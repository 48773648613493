.support-modal.i-modal {
  overflow: hidden;
  z-index: 50;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  padding: 0;
  opacity: 1;
  transition-property: transform;
  transform: translateX(100%);
  background-color: transparent;

  &.is-active {
    opacity: 1;
    visibility: visible;
    z-index: 50;
    transform: translateX(0);

    .i-modal__content {
      opacity: 1;
    }
  }
  
  .i-modal__content {
    max-width: 940px;
    opacity: 1;
    margin: 0;
    margin-left: auto;
    padding: 0;
    padding-top: 96px;
    width: 100%;
    height: 100%;
    border-radius: 0;
    transform: none;
  }

  .i-modal__inner {
    position: relative;
    height: 100%;
    // overflow: auto;
    // overflow-x: hidden;
  }

  form {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .support-modal__header {
    position: sticky;
    z-index: 4;
    top: 0;
    background-color: $white;
    left: 0;
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 24px;

    // border-bottom: 2px solid #e8eaee;

    padding-bottom: 24px;

    &.is-scrolled {
      margin-bottom: 0;
      &::before {
        display: none;
      }
    }
  }

  .support-modal__add-txt {
    display: none;
    align-items: center;
    column-gap: 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
    margin-bottom: 8px;
    color: $grey;
    cursor: pointer;

    svg {
      width: 18px;
      height: 18px;
      stroke: $grey;
      fill: none;
      transform: rotate(90deg);
    }
  }

  .support-modal__header-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .support-modal__header-buttons {
    display: flex;
    align-items: center;
    column-gap: 16px;

    .btn {
      display: none;
    }
  }

  .btn.support-modal__close {
    display: inline-flex;
  }

  .support-modal__remove {
    svg {
      stroke: $red-btn;
    }
  }

  .support-modal__title {
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
    color: $black;
  }

  .support-modal__main {
    position: relative;
    padding: 0;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    // grid-template-columns: repeat(2, 1fr);
    // gap: 44px 24px;
    overflow: auto;
    height: calc(100% - 174px);
  }

  .support-modal__bottom {
    position: absolute;
    left: 0;
    z-index: 2;
    bottom: 0;
    padding: 24px 40px;
    background-color: $white;
    border-top: 2px solid #e8eaee;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 20px;

    .btn {
      max-width: 240px;
      width: 100%;
    }
  }
}

.support-modal {
  .hidden {
    display: none;
  }

  &__success {
    position: absolute;
    inset: 0;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 16px;
    z-index: 3;

    &-wrap {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    h2 {
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
    }

    span {
      padding-top: 4px;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }

    &-btn {
      padding-top: 16px;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      color: $violet;
      cursor: pointer;
    }

  }
}

@media (max-width: $mobile-big) {
  .support-modal.i-modal {
    .i-modal__content {
      padding-top: 60px;
    }

    .support-modal__header {
      padding: 20px;
    }

    .support-modal__main {
      display: flex;
      flex-direction: column;
      row-gap: 24px;
      // grid-template-columns: 1fr;
      // align-items: flex-start;
      // justify-content: flex-start;
    }

    .group__form,
    .group__additional-form {
      grid-template-columns: 1fr;
    }

    .long {
      grid-column-end: span 1;
    }

    .support-modal__grid {
      grid-template-columns: 1fr;
      row-gap: 24px;
    }

    .support-modal__title {
      font-size: 22px;
    }

    .group__title-subgroup {
      grid-column-start: 1;
      grid-column-end: 1;
    }

    .support-modal-content {
      &__info {
        padding: 30px 25px;
      }
      &__inpt-wrap {
        padding: 30px 25px;
      }
    }
  }
}

@media (max-width: $mobile) {
  .support-modal.i-modal {

    .support-modal__bottom {
      flex-direction: column;
      row-gap: 10px;
      padding: 10px 40px;
    }
  }
}



.support-modal-content {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__info {
    background-color: rgba(244, 243, 255, 1);
    padding: 32px 40px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    &-item {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }

    &-icon {
      min-width: 40px;
      min-height: 40px;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      color: $violet;
      background-color: rgba(255, 255, 255, 1);
    }
  }

  &__inpt-wrap {
    display: flex;
    flex-direction: column;
    padding: 44px 40px 24px;
    gap: 44px;
    flex: 1;
  }

  &__textarea-wrap {
    height: 100%;

    .textarea {
      height: 100%;
      textarea {
        min-height: 130px;
        height: 100%;
      }
    }
    
    span {
      padding: 4px 16px 0;
      color: $grey;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }
  }

  &__title {
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    padding-bottom: 8px;
  }
}