.register-form {
  width: 440px;
  margin: 0 auto;

  &__fields {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
  }

  &__bottom {
    margin-top: 44px;

    .submit-button {
      width: 100%;
      margin-bottom: 16px;
    }

    p {
      text-align: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: $violet-grey;
    }

    a {
      color: $violet;
    }
  }
}
