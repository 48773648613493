.login-form {
  width: 440px;
  margin: 0 auto;

  form {
    .input-custom:first-of-type {
      margin-bottom: 30px;
    }
  }

  &__forgot {
    display: inline-block;
    font-weight: 500;
    margin-top: 24px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: $violet;
  }

  &__bottom {
    margin-top: 44px;

    .submit-button {
      width: 100%;
      margin-bottom: 16px;
    }

    p {
      text-align: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: $violet-grey;
    }

    a {
      color: $violet;
    }
  }
}
