.hero {
  overflow: hidden;
  padding-top: 52px;
  padding-bottom: 40px;

  &.fix-height {
    height: 338px;
  }

  &__inner {
    position: relative;
  }

  &__title {
    color: $violet;
    br.adaptive {
      display: none;
    }
  }

  &__back {
    margin-bottom: 8px;
  }

  &__add-patient {
    width: 210px;
    position: absolute;
    right: 0;
    bottom: 0;
  }

  &__image {
    position: absolute;
    z-index: -1;
    top: -53px;
    right: 115px;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__passed-time {
    margin-top: 24px;
    display: inline-block;
    padding: 8px 20px;
    border-radius: 10px;
    background-color: $white;
    // txt
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: $grey;
  }
}

@media (max-width: $desktop) {
  .hero {
    &__title {
      font-size: 60px;
    }

    &__image {
      top: 0;
      right: 50px;
      width: 540px;
    }
    // br.adaptive {
    //   display: block;
    // }
  }
}

@media (max-width: $tablet) {
  .hero {
    &:has(.hero__image) {
      padding-bottom: 194px;
    }

    &__title {
      font-size: 44px;
      br {
        display: none;
      }
    }

    &__image {
      top: 75px;
      right: 55px;
      position: absolute;
      // display: none;
    }
    // br.adaptive {
    //   display: block;
    // }
  }
}

@media (max-width: $mobile-big) {
  .hero {
    padding-top: 32px;
    padding-bottom: 32px !important;

    &.fix-height {
      height: auto;
    }

    &__title {
      font-weight: 500;
      line-height: 1.2;
    }

    &__image {
      display: none;
    }

    &__add-patient {
      position: relative;
    }
  }

  .patient-list {
    .hero {
      &__inner {
        display: flex;
        align-items: center;
        column-gap: 20px;
      }

      &__add-patient {
        flex-shrink: 0;
        width: 65px;
        height: 65px;
        border-radius: 50%;
        span {
          display: none;
        }

        svg {
          flex-shrink: 0;
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}
