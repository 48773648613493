.file-loader {
  margin-bottom: 4px;
  display: flex;
  width: 100%;
  gap: 8px;
  flex-wrap: wrap;

  &__content {
    &:empty {
      display: none;
    }

    display: flex;
    gap: 8px;
    flex-wrap: wrap;
  }

  &__add-file {
    cursor: pointer;
    width: 52px;
    height: 52px;
    padding: 17px;
    border-radius: 10px;
    border: 1px dashed $violet;
    display: flex;
    align-items: center;
    justify-content: center;

    input[type='file'] {
      display: none;
    }

    svg {
      flex-shrink: 0;
      width: 18px;
      height: 18px;
      stroke: $violet;
    }
  }
}
