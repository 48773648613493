.group {
  width: 100%;
  opacity: 0;
  background-color: $white;
  border-radius: 20px;
  // overflow: hidden;

  &.is-filled {
    & > .group__header {
      .group__completed {
        display: block;
      }

      .group__subtitle {
        padding-left: 40px;
      }
    }
  }

  &.is-active {
    .group__header {
      border-color: $grey-super-light;
    }

    .group__inner-wrapper {
      // height: auto;
      overflow-x: visible;
      overflow-y: initial;
      // overflow: initial;
    }

    .group__subtitle {
      display: block;
    }

    .group__inner {
      opacity: 1;
    }

    .group__toggle {
      svg {
        transform: rotate(0);
      }
    }
  }

  &__header {
    cursor: pointer;
    padding: 24px 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid transparent;
    @include transition('border, height');
  }

  &__title {
    display: flex;
    align-items: center;
    column-gap: 8px;
    // txt
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
  }

  &__subtitle {
    display: none;
    // position: absolute;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: $grey;
  }

  &__title-subgroup {
    color: $violet;
    font-size: 22px;
    font-weight: 500;
    line-height: 28px;
    display: flex;
    align-items: flex-end;
    column-gap: 16px;

    span {
      white-space: nowrap;
    }

    &::after {
      bottom: 6px;
      position: relative;
      content: '';
      display: inline-block;
      width: 100%;
      height: 2px;
      background-color: $grey-super-light;
    }
  }

  // &__additional-title {
  //   margin-bottom: 44px;
  // }

  &__completed {
    display: none;
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    svg {
      stroke: $green;
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__toggle {
    cursor: pointer;
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    svg {
      @include transition('transform');
      stroke: $grey-light;
      pointer-events: none;
      transform: rotate(180deg);
    }
  }

  &__inner-wrapper {
    position: relative;
    height: 0;
    overflow-y: hidden;
    // visibility: hidden;
    @include transition('height, overflow');
  }

  &__inner {
    @include transition('opacity');
    opacity: 0;
  }

  &__form,
  &__additional-form {
    padding: 44px 32px;
  }

  &--additional {
    border-radius: 0 0 20px 20px;
    opacity: 1;
    border-top: 2px solid $grey-super-light;
    .group__header {
      cursor: auto;
      padding-top: 44px;
      padding-bottom: 0;
      border: none;
    }
    display: none;
    &.is-active {
      display: block;
    }
  }

  &--additional[data-connected] {
    &.is-active {
      display: block;
    }
    display: none;
  }

  &--simple {
    .group__header {
      padding: 32px 40px;
      border-bottom: none;
    }

    .group__form {
      padding: 0 40px 32px;
    }

    .group__form:empty {
      padding: 0;
    }

    background-color: $background;
    border-radius: 0;
  }

  &--additional.group--simple {
    position: relative;
    border: none;
    &:before {
      content: '';
      width: calc(100% - 80px);
      background-color: $grey-super-light;
      height: 2px;
      position: absolute;
      top: 0;
      left: 40px;
    }

    .group__additional-form {
      padding: 0 40px 32px;
    }
  }

  &__info {
    padding: 44px 32px;
    color: $violet;
    font-weight: 500;
    letter-spacing: 0.15px;
  }

  &__add-info {
    display: inline-block;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: $grey-light;
    align-self: end;
    margin-left: 8px;
  }
}

@media (max-width: $mobile-big) {
  .group {
    &--simple {
      .group__header {
        padding: 32px 20px;
      }

      .group__form {
        padding: 0 20px 32px;
      }
    }

    &--additional.group--simple {
      .group__additional-form {
        padding: 0 20px 32px;
      }

      &:before {
        width: calc(100% - 40px);
        left: 20px;
      }
    }

    &__header {
      padding: 24px 20px;
      column-gap: 8px;
    }

    &__form,
    &__additional-form {
      padding: 44px 20px;
    }

    &__title {
      // txt
      align-items: flex-start;
      font-size: 20px;
    }
  }
}

@media (max-width: $mobile) {
  .group {
    &__title-subgroup {
      display: block;
      &::after {
        display: none;
      }
    }
  }
}
