@mixin transition($element: all, $time: 0.25s, $function: linear) {
  transition: $time $function;
  transition-property: #{$element};
}

@mixin transition-cubic($time: 0.25s, $function: cubic-bezier(1, 0, 0, 1), $element: all) {
  transition: $element $time $function;
}

//example convertVH:
//min-height: calc(#{convertVH(100)} - 20px);
//height: calc(#{convertVH(100)} / 2 + 40px);
@function convertVH($val) {
  @return calc(var(--vh, 1vh) * #{$val});
}

//example heightVH:
//@include heightVH(100, 'min');
//@include heightVH(80, 'max');
//@include heightVH(100);
@mixin heightVH($val: 100, $pre: '') {
  $vh: calc(var(--vh, 1vh) * #{$val});
  @if $pre != '' {
    #{$pre}-height: $val + vh;
    #{$pre}-height: $vh;
  } @else {
    height: $val + vh;
    height: $vh;
  }
}

@mixin scrollbar($width: 10px, $rail-color: $grey-super-light, $bar-color: $grey-light, $border-radius: 10px) {
  -webkit-overflow-scrolling: touch;
  scrollbar-color: $bar-color $rail-color;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: $width;
    height: $width;
    background-color: $rail-color;
  }
  &::-webkit-scrollbar-track {
    background-color: $rail-color;
    border-radius: $border-radius;
  }
  &::-webkit-scrollbar-thumb:vertical {
    background-color: $bar-color;
    border-radius: $border-radius;
  }
  &::-webkit-scrollbar-thumb:horizontal {
    background-color: $bar-color;
    border-radius: $border-radius;
  }
}

@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}

@mixin placeholder {
  @include optional-at-root('::-webkit-input-placeholder') {
    @content;
  }

  @include optional-at-root(':-moz-placeholder') {
    @content;
  }

  @include optional-at-root('::-moz-placeholder') {
    @content;
  }

  @include optional-at-root(':-ms-input-placeholder') {
    @content;
  }
}

@mixin violetLight {
  animation: shadowViolet 3s linear 0s infinite;
}

@mixin redLight {
  animation: shadowRed 3s linear 0s infinite;
}

@keyframes shadowViolet {
  0% {
    box-shadow: 0px 0px 0px 0px $violet;
  }
  50% {
    box-shadow: 0px 0px 0px 1px $violet;
  }
  100% {
    box-shadow: 0px 0px 0px 0px $violet;
  }
}

@keyframes shadowRed {
  0% {
    box-shadow: 0px 0px 0px 0px $input-error;
  }
  50% {
    box-shadow: 0px 0px 0px 1px $input-error;
  }
  100% {
    box-shadow: 0px 0px 0px 0px $input-error;
  }
}

// @include placeholder {
//     color: green;
// }
