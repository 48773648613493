.info {
  width: 24px;
  height: 24px;
  background-color: transparent;
  cursor: pointer;

  svg {
    width: 100%;
    height: 100%;
    fill: none;
    stroke: $grey;
    pointer-events: none;
  }
}