.input-custom {
  position: relative;
  &__input {
    width: 100%;
    position: relative;

    // Стили если есть икона календаря
    &:has(.calendar-toggler) {
      input {
        padding-right: 50px;
      }

      label {
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: calc(100% - 60px);
      }
    }

    &:has(.button-eye) {
      input {
        padding-right: 48px;
      }
    }
  }

  &[data-connected] {
    display: none;
  }
  &[data-connected].is-active {
    display: block;
  }

  &:has(input[data-connected]) {
    display: none;
  }

  &:has(input[data-connected].is-active) {
    display: block;
  }

  &:has(input[data-required].hightlight) {
    input {
      @include violetLight;
      border-color: $violet;
    }
  }

  // &.is-invalid {
  //   input {
  //     border-color: $input-error;
  //     & ~ label {
  //       color: $input-error;
  //     }
  //   }

  //   input:focus {
  //     box-shadow: 0px 0px 0px 1px $input-error;
  //   }

  //   &__message {
  //     display: block;
  //     color: $input-error;
  //   }
  // }

  // стили для input
  input {
    display: block;
    width: 100%;
    height: 56px;
    padding: 16px;
    font-family: $default-font;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: $grey;
    background-color: $white;
    background-clip: border-box;
    border: 1px solid $grey;
    border-radius: 4px;
    appearance: none;
    @include transition('border-color, box-shadow');

    &.input-custom--violet {
      background-color: $background;
      & ~ label {
        background-color: $background;
      }
    }
  }

  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  // стили для placeholder. скрываем его
  input::placeholder {
    color: transparent;
    &::-moz-placeholder {
      color: transparent;
    }
  }

  // стили при hover на input
  input:hover,
  input:not(:placeholder-shown):not(.is-invalid):hover {
    border-color: $violet;
  }

  // стили при focuse на input
  input:focus {
    border-color: $violet !important;
    animation: none !important;
    box-shadow: 0px 0px 0px 1px $violet;
    & ~ label {
      color: $violet;
    }
  }

  input:not(:placeholder-shown):not(.is-invalid):focus ~ label {
    color: $violet;
  }

  // Стили если Input не пустой :not(:placeholder-shown) или focus
  input:focus ~ label,
  input:not(:placeholder-shown) ~ label {
    top: 0;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
  }

  input:not(:placeholder-shown) {
    color: $grey-dark;

    &:not(.is-invalid).hightlight {
      border-color: $grey;
      animation: none;
    }

    & ~ label {
      color: $grey;
    }
  }

  // стили при disabled
  &:has(input:disabled) {
    pointer-events: none;
    opacity: 0.5;
  }

  // не валидные значения
  &:has(input.is-invalid) {
    input {
      border-color: $input-error !important;
      & ~ label {
        color: $input-error;
      }
    }

    .input-custom__message {
      display: block;
    }
  }

  &:has(input[data-required].is-invalid.hightlight) {
    input {
      @include redLight;
    }
  }

  input.is-invalid:focus {
    box-shadow: 0px 0px 0px 1px $input-error;
  }

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 50px $white inset;
    -webkit-text-fill-color: $grey;
  }

  // label
  label {
    max-width: calc(100% - 32px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
    pointer-events: none;
    border: none;
    background-color: $white;
    color: $grey;
    padding: 0 4px;
    // txt
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    @include transition('top, font-size', 0.2s);
  }

  // дополнительный текст
  &__message {
    display: none;
    position: absolute;
    top: calc(100% + 4px);
    left: 16px;
    // margin-top: 4px;
    // padding-left: 16px;
    color: $grey;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;

    &.error-message {
      // display: block;
      color: $input-error;
    }
  }

  &.have-message {
    .input-custom__message {
      display: block;
    }
  }

  &__info {
    position: absolute;
    cursor: pointer;
    top: 50%;
    transform: translateY(-50%);
    right: 12px;
    width: 24px;
    height: 24px;
    fill: none;
    stroke: $grey;
  }

  // Modifications
  &--support-txt {
    .input-custom__message {
      display: block;
    }
  }

  &--info {
    input {
      padding-right: 40px;
    }
  }

  &--ghost {
    input {
      height: auto;
      border: none;
      padding: 12px 0;
    }

    input:focus {
      box-shadow: none;
    }

    input:focus ~ label,
    input:not(:placeholder-shown) ~ label {
      opacity: 0;
      top: 50%;
      font-size: 16px;
      line-height: 1.5;
      letter-spacing: normal;
    }

    label {
      left: 0;
    }

    .calendar-toggler {
      width: 24px;
      height: 24px;
      right: 0;
      svg {
        @include transition('stroke');
        stroke: $grey;
      }

      @media (hover: hover) {
        &:hover {
          background-color: transparent;
          svg {
            stroke: $violet;
          }
        }
      }
    }
  }

  .button-eye {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 12px;
    width: 24px;
    height: 24px;
    justify-content: center;

    svg {
      width: 100%;
      height: 100%;
      stroke: $grey;
    }
  }
}
