.logo-full {
  display: block;
  width: 440px;
  text-align: center;

  p {
    color: $violet;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
  }
}
