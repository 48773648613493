.home-content {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__frame {
    height: 560px;
    border-radius: 20px;
    background-color: $white;
  }
}