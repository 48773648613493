.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.apple-fix {
  width: 100%;
  position: fixed;
}

.no-scroll {
  overflow: hidden;
}

.clearfix {
  &::after {
    content: '.';
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  @include heightVH(100, min); //ios
  width: 100%;
  // overflow-x: hidden;
  position: relative;

  // display: flex;
  // flex-direction: column;
  // min-height: 100vh;
  // @include heightVH(100, min); //ios
  // // overflow: hidden;
  // width: 100%;
  // overflow-x: hidden;
  // position: relative;

  main {
    padding-top: 96px;
  }

  &.new-patient {
    main {
      padding-bottom: 92px;
    }
  }

  &.patient-card {
    main {
      padding-bottom: 67px;
    }
  }

  &.profile {
    main {
      padding-bottom: 52px;
    }
  }

  &.creating-operation {
    main {
      padding-bottom: 92px;
    }
  }

  &.patient-list {
    main {
      padding-bottom: 40px;
    }

    .table-container {
      min-width: 1440px;
    }
  }

  &.support {
    main {
      padding-bottom: 60px;
    }
  }

  &.login,
  &.register,
  &.recovery {
    &.is-success {
      .register-form,
      .login-form,
      .recovery-password-form {
        display: none;
      }

      .success-block {
        display: flex !important;
      }
    }

    main {
      padding: 0;
      background-image: url('../img/bg-bareo.jpg');
      background-size: cover;
    }
  }

  &.sticky {
    overflow: unset;
  }
  .header {
    flex: 0 0 auto;
  }
  main {
    position: relative;
    flex: 1 0 auto;
  }
  .footer {
    flex: 0 0 auto;
  }
}
.is-default-hidden {
  display: none !important;
}
// @supports (-webkit-line-clamp: 6) {
// 	.your-class {
// 			display: -webkit-box;
// 			white-space: normal;
// 			text-overflow: ellipsis;
// 			-webkit-box-orient: vertical;//
// 			-webkit-line-clamp: 6;
// 			overflow: hidden;
// 	}
// }

.tippy-box[data-theme~='violet'] {
  background-color: $violet;
  color: $white;
}

.tippy-box[data-theme~='violet'][data-placement^='top'] > .tippy-arrow::before {
  border-top-color: $violet;
}
.tippy-box[data-theme~='violet'][data-placement^='bottom'] > .tippy-arrow::before {
  border-bottom-color: $violet;
}
.tippy-box[data-theme~='violet'][data-placement^='left'] > .tippy-arrow::before {
  border-left-color: $violet;
}
.tippy-box[data-theme~='violet'][data-placement^='right'] > .tippy-arrow::before {
  border-right-color: $violet;
}

.svg {
  flex-shrink: 0;
  fill: none;
  width: 100%;
  height: 100%;
}

.hidden-input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.tooltip-menu {
  @include transition('opacity, visibility');
  position: absolute;
  opacity: 0;
  visibility: hidden;
  top: 100%;
  right: 0;
  z-index: 5;
  // width: 144px;
  height: auto;
  padding: 8px 0;
  list-style: none;
  background: linear-gradient(0deg, rgba(103, 80, 164, 0.08), rgba(103, 80, 164, 0.08)), #fffbfe;
  border-radius: 4px;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.3);

  &.is-active {
    opacity: 1;
    visibility: visible;
  }
}

.tooltip-menu__option {
  cursor: pointer;
  min-width: 144px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 16px;
  padding: 12px 16px;

  @media (hover: hover) {
    &:hover {
      background-color: rgba($violet, $alpha: 0.1);
    }
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;

    &.loading {
      opacity: 1;
    }
  }

  &.loading {
    svg {
      display: none;
    }
  }

  &.logout {
    svg {
      stroke: $red-btn;
    }
    color: $red-btn;
  }

  // txt
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: $grey-dark;
  white-space: nowrap;

  svg {
    @include transition('stroke');
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    fill: none;
    stroke: $grey;
  }
}

.login-logo {
  margin: 0 auto;
  margin-bottom: 40px;
}

.success-block {
  display: none !important;
  margin: 0 auto;
}

.login-container {
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  row-gap: 80px;
}

.home-container {
  padding-top: 52px;
  @media (max-width: $mobile) {
    padding: 24px 12px 0;
  }
}

@media (max-width: $mobile-big) {
  .wrapper {
    main {
      padding-top: 60px;
    }
  }
}

@media (max-width: $mobile) {
  .wrapper {
    &.new-patient {
      main {
        padding-bottom: 40px;
      }
    }
  }

  .group__delete {
    span {
      display: none;
    }
  }
}

.response-message {
  font-weight: 500;
  opacity: 0;
  @include transition('opacity');

  &.is-active {
    opacity: 1;
  }

  &.error {
    color: $color-error;
  }

  &.success {
    color: $green;
  }
}

@media only print {
  .body {
    overflow: visible !important;
  }

  .header,
  .div[data-tippy-root] {
    display: none !important;
  }

  // group отступы
  .group__header {
    padding-bottom: 20px !important;
  }
  .group__form {
    padding-bottom: 0 !important;
  }

  // - - - Печать операции Начало - - -
  .i-modal {
    position: relative !important;
    height: auto !important;
    bottom: auto !important;
  }

  .i-modal__content {
    padding-top: 0 !important;
  }

  .side-modal__header-buttons,
  .side-modal__bottom,
  .group__delete,
  .add-intervention,
  .group__completed {
    display: none !important;
  }

  // .checkbox input:checked + .checkbox__fake:before {
  //   background-image: url('../img/icons/check_small-black.svg') !important;
  // }

  .side-modal__main {
    padding-top: 0 !important;
    width: auto !important ;
    height: auto !important ;
    overflow: visible !important ;

    .group__toggle {
      display: none;
    }
  }

  .i-modal__content {
    height: auto !important;
  }
  // - - - Печать операции. Конец - - -

  // - - - Печать наблюдения - - -
  .file-loader {
    display: none !important;
  }

  // - - -  Карта пациента - - -
  .patient-card {
    .hero__back,
    .general-info__actions,
    .page-section__actions,
    .patient-card__bottom {
      display: none;
    }
  }
}
