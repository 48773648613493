/* smartphones, touchscreens */
//@media (hover: none) and (pointer: coarse) {}
/* stylus-based screens */
//@media (hover: none) and (pointer: fine) {}
/* Nintendo Wii controller, Microsoft Kinect */
//@media (hover: hover) and (pointer: coarse) {}
/* mouse, touch pad */
//@media (hover: hover) and (pointer: fine) {}
/* hide scrollbar */
//html::-webkit-scrollbar { width: 0 !important }

%scrollbar {
  @include scrollbar(10px, transparent, $grey-light);
  &::-webkit-scrollbar-track,
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
  }
}

@at-root {
  @-ms-viewport {
    // stylelint-disable-line
    width: device-width;
  }
}
[data-whatintent='touch'],
[data-whatintent='mouse'] {
  *:focus {
    outline: none;
  }
}
* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  //outline: none;
  @extend %scrollbar;
}

*::before,
*::after {
  box-sizing: inherit;
}

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-size-adjust: 100%; // stylelint-disable-line
  // height: auto !important;
  // 	touch-action: manipulation;
}
body {
  position: relative;
  font-family: $default-font;
  font-size: $font-size;
  line-height: $line-height;
  color: $black;
  background-color: $background;

  // overflow-x: hidden;
  // overflow-y: unset;
  overflow-y: visible;
  text-align: left;
  cursor: default;
  word-break: break-word;
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @media (max-width: $mobile-big) {
    font-size: 14px;
  }

  // &::-webkit-scrollbar {
  //   display: none;
  // }
}

[tabindex='-1']:focus {
  outline: none !important; // stylelint-disable-line
}
// Коррекция для кликабельных элементов с указанной ролью в iOS
[role='button'] {
  cursor: pointer;
}
//for Safari
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none; // stylelint-disable-line
}

// area,
// summary,
a,
button,
[role='button'],
input,
label,
select,
textarea {
  touch-action: manipulation; // Стилевая коррекция 300ms задержки (для поддерживающих браузеров)
  margin: 0; // Убираем margin в Firefox и Safari
}
a {
  text-decoration: none;
  color: inherit;
}
button,
input {
  overflow: visible; // Покажем торчащее в Edge
  text-transform: none; // Уберём наследование text transform в Firefox
}
// Коррекция проблем стилизации для Safari
button,
html [type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button; // stylelint-disable-line
}
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
button {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
}
// Коррекция для Mobile Safari (https://github.com/twbs/bootstrap/issues/11266)
input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox; // stylelint-disable-line
}
input[type='submit'] {
  width: auto;
}
input[type='number'] {
  -moz-appearance: textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
//for safari
input[type='search'] {
  -webkit-appearance: textfield;
}
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
textarea {
  overflow: auto; // Удаление верт. скролла в IE.
  resize: vertical; // Правильное изменение размеров
}
svg {
  fill: currentColor;
  a &,
  button & {
    pointer-events: none; // Мы любим Edge, это браузер! (почти)
  }
  &:not(:root) {
    overflow: hidden; // Скрыть выступающее за границы в IE
  }
}
img {
  max-width: 100%;
  height: auto;
  border: none;
}
hr {
  box-sizing: content-box; // Проблема Firefox
  height: 0; // Проблема Firefox
  overflow: visible; // Проблема Edge и IE
  margin-top: 1em * 2;
  margin-bottom: 1em * 2;
  border: 0;
  border-top: 1px solid hsl(0, 0%, 70%);
}
p,
ul,
ol,
dl,
table,
blockquote,
pre,
address,
figure {
  margin-top: 0;
  margin-bottom: 0; //1em
}
p {
  // http://clagnut.com/blog/2395
  hyphens: auto;
  hyphenate-limit-chars: 6 3 3;
  hyphenate-limit-lines: 2;
  hyphenate-limit-last: always;
  hyphenate-limit-zone: 8%;
  padding: 0;
  margin: 0;
}
address {
  font-style: normal;
}
ul,
ol {
  margin: 0;
  &.no-style {
    padding: 0;
    list-style: none;
  }
}
li {
  line-height: inherit;
}
blockquote {
  padding: 0;
  margin: 0;
}
code {
  padding: 0.06em 0.3em;
  color: $grey-light;
  background-color: rgba(#000, 0.08);
  border-radius: 3px;
}
kbd {
  padding: 0.06em 0.3em;
  color: $grey-light;
  background-color: hsl(0, 0%, 90%);
  border-radius: 3px;
  kbd {
    padding: 0;
  }
}
pre {
  display: block;
  width: 100%;
  margin-bottom: 0;
  overflow-x: auto;
  tab-size: 2;
  background-color: rgba(#000, 0.08);
  code {
    background: transparent;
  }
}
[contenteditable='true']:empty:before {
  content: attr(data-placeholder);
  color: #131313;
  font-size: inherit;
  display: block; /* For Firefox */
}
select {
  appearance: none;
  &::-ms-expand {
    // для IE
    display: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  color: currentColor;
  margin: 0;
  padding: 0;
}
h1 {
  font-size: 70px;
  font-weight: 500;
  line-height: 77px;
}
h2 {
  font-size: 42px;
  line-height: 51px;
  font-weight: 700;
}
h3 {
  font-size: 28px;
  line-height: 34px;
}
h4 {
  font-size: 22px;
}

.container {
  max-width: 1440px; //1300
  width: 100%;
  margin: auto;
  padding: 0 $grid-spacing;
  @media (max-width: $mobile) {
    padding: 0 $grid-spacing-sm;
  }
}
