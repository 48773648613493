.statistics {
  background-color: $white;
  padding: 4px 16px 4px 4px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 16px;
  border-radius: 16px;

  &__icon {
    width: 78px;
    height: 78px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $background;
    border-radius: 20px;

    svg {
      width: 38px;
      height: 38px;
      fill: none;
      stroke: $violet;
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    column-gap: 16px;
  }

  &__title {
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
  }

  &__subtitle,
  &__total {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.5px;
    color: $violet-grey;
  }

  &__total {
    align-self: start;
  }
}
