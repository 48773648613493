.user {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  column-gap: 8px;

  &.is-open {
    .user__carret {
      transform: rotate(0deg);
    }
  }

  &__avatar-template {
    width: 40px;
    height: 40px;
    border: 1px solid $grey;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 24px;
      height: 24px;
      fill: none;
      stroke: $grey;
    }
  }

  &__avatar {
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__username {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    color: $grey-dark;
  }

  &__carret {
    @include transition('transform');
    width: 18px;
    height: 18px;
    stroke: $grey;
    fill: none;

    transform: rotate(180deg);
  }

  .tooltip-menu {
    z-index: 100;
  }
}

@media (max-width: $tablet) {
  .user {
    &__username {
      font-size: 18px;
    }
  }
}

@media (max-width: $mobile) {
  .user {
    &__carret,
    &__username {
      display: none;
    }
  }
}
