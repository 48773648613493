.btn {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
  height: 40px;
  border: 1px solid $violet;
  border-radius: 100px;
  cursor: pointer;
  color: $white;
  background-color: $violet;
  margin: 0;
  padding: 0 24px;
  z-index: 1;
  @include transition('opacity');

  // txt
  font-family: inherit;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  user-select: none;

  @media (hover: hover) {
    &:hover {
      opacity: 0.85;
    }
  }

  &:active {
    opacity: 0.75;
  }

  &.disabled,
  &[disabled] {
    // pointer-events: none;
    opacity: 0.5;
  }

  &[data-connected] {
    display: none;
  }
  &[data-connected].is-active {
    display: inline-flex;
  }

  svg {
    width: 18px;
    height: 18px;
    fill: none;
    stroke: $white;
    transition: 0.25s linear;
    transition-property: color, stroke;
  }

  &--ghost {
    background-color: transparent;
    border-color: $grey-light;
    color: $violet;

    svg {
      stroke: $violet;
    }
  }

  &--text {
    justify-content: flex-start;
    background-color: transparent;
    border: none;
    height: auto;
    padding: 0;
    white-space: nowrap;
    color: $violet;

    svg {
      stroke: $violet;
    }

    span {
      // font-size: 16px;
      // font-weight: 400;
      // line-height: 24px;
      @include transition('color');
    }
  }

  &--only-icon {
    width: 24px;
    height: 24px;
    background-color: transparent;
    padding: 0;
    border: none;

    svg {
      stroke: $violet;
      width: 100%;
      height: 100%;
    }
  }

  &--red {
    background-color: $red-btn;
    border-color: $red-btn;
  }
}
