.observations {
  padding: 0 40px;
  margin-bottom: 28px;

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }

  &__title {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    margin-bottom: 16px;
  }

  &__add,
  &__observation {
    flex-shrink: 0;
    max-width: 100px;
    width: 100%;
    background-color: transparent;
    border: 1px solid $violet;
    border-radius: 8px;
    outline: none;
    padding: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    // txt
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;

    svg {
      width: 18px;
      height: 18px;
      fill: none;
      stroke: $violet;
    }
  }

  &__observation {
    border-color: $grey-light;
  }
}

.side-modal__add-complication {
  display: none;
  &.is-active {
    display: block;
  }
}

@media (max-width: $mobile-big) {
  .observations {
    padding: 0 20px;
  }
}
