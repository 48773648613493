.recovery-password-form {
  width: 440px;
  margin: 0 auto;

  &__subtitle {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: $grey;
    margin-bottom: 24px;
  }

  &__bottom {
    margin-top: 44px;

    .submit-button {
      width: 100%;
      margin-bottom: 16px;
    }
  }

  &__links {
    display: flex;
    justify-content: space-between;

    a {
      color: $violet;
      text-align: center;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      flex-grow: 1;
    }
  }

  .page-section__header {
    margin-bottom: 4px;
  }
}
