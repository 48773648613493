// СТИЛИ КАСТОМНОГО ЧЕКБОКСА
.checkbox {
  display: inline-flex;
  justify-self: self-start;
  column-gap: 8px;
  align-items: flex-start;
  // align-items: center;

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }

  .checkbox__fake {
    padding: 3px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    user-select: none;
  }

  // Вид чекбокса
  .checkbox__fake::before {
    @include transition('border-color');
    content: '';
    display: inline-block;
    width: 18px;
    height: 18px;
    flex-shrink: 0;
    flex-grow: 0;
    border: 2px solid #515151;
    border-radius: 2px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
  }

  // Состояние фокус
  input:focus + .checkbox__fake::before {
    border-color: $violet;
  }

  // Вид чекбокса в состоянии checked
  input:checked + .checkbox__fake::before {
    background-color: $violet;
    border-color: $violet;
    background-image: url('../img/icons/check_small.svg');
    background-repeat: no-repeat;
    background-size: 100%;
  }

  // hover
  input:not(:disabled):not(:checked) + .checkbox__fake:hover {
    &::before {
      border-color: $violet;
    }
  }

  // hover если чекбокс чекнут
  // input:not(:disabled):checked + .checkbox__fake:hover {
  //   &::before {
  //     border-color: yellow;
  //     background-color: yellow;
  //   }
  // }

  // Состояние disable
  input:disabled + .checkbox__fake::before {
    opacity: 0.4;
  }
}

// Стили для текста label
.checkbox__label {
  font-weight: 400;
  line-height: 24px;
  font-size: 16px;
  color: $grey-light;
  user-select: none;
  cursor: pointer;
}
