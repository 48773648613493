.success {
  width: 440px;
  padding: 70px 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: $white;
  border-radius: 20px;

  &--email {
    .success__subtitle {
      font-weight: 500;
    }
  }

  img {
    width: 125px;
    height: 125px;
    border-radius: 50%;
    box-shadow: 0px 4px 20px 0px rgba(83, 240, 31, 0.25);
    margin-bottom: 33px;
  }

  &__title {
    text-align: center;
    font-size: 24px;
    line-height: 32px;
    align-items: center;
    font-weight: 500;
    margin-bottom: 4px;
  }

  &__subtitle {
    text-align: center;
    font-size: 14px;
    letter-spacing: 0.25px;
    line-height: 20px;
    color: $grey;
  }
}
