.page-section {
  padding: 32px;
  background-color: $white;
  border-radius: 20px;

  &.not-empty {
    .placeholder {
      display: none;
    }
  }

  &--additional {
    padding: 0;
    background-color: transparent;
    border: initial;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 44px;
    margin-bottom: 24px;
  }

  &__title {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    color: $black;
  }

  &__actions {
    display: flex;
    column-gap: 16px;
  }

  &__message {
    display: none;
    margin-top: 24px;
    font-weight: 500;
    color: $green;

    &.is-active {
      display: block;
    }

    &.error {
      color: $input-error;
    }
  }
}

@media (max-width: $mobile-big) {
  .page-section {
    padding: 20px;
    &--additional {
      padding: 0;
    }

    &__header {
      flex-direction: column;
      align-items: flex-start;
      row-gap: 24px;
    }
    &__actions {
      // align-self: flex-end;
      flex-direction: column;
      row-gap: 16px;
    }
  }
}

// @media (max-width: $mobile-big) {
//   .page-section {
//     padding: 16px;
//   }
// }
