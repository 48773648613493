.tabs-wrapper {
  display: inline-flex;
  position: relative;
  // &:before {
  //   content: '';
  //   position: absolute;
  //   left: 0;
  //   bottom: 0;
  //   width: 100%;
  //   height: 2px;
  //   border-radius: 20px;
  //   background-color: #cac4d0;
  // }
}

.tab {
  display: inline-block;
  padding: 12px;
  padding-top: 0;
  position: relative;
  cursor: pointer;

  @media (hover: hover) {
    &:hover {
      opacity: 0.85;
    }
  }

  &.is-active {
    &:before {
      background-color: $violet;
      height: 4px;
    }

    color: $violet;
  }

  &:before {
    @include transition('background-color');
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    border-radius: 20px;
    background-color: #cac4d0;
  }

  @include transition('color');
  // font
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.15px;

  &__name {
    margin-right: 4px;
  }
}
