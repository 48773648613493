.modal-upload {
  &__files {
    margin-bottom: 24px;
  }

  &__btns {
    display: flex;
    column-gap: 20px;
    margin-top: 44px;

    .btn {
      flex-grow: 1;
    }
  }
}
