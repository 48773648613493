.form-creating-operation__general-information {
  .group__form {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: flex-start;
    gap: 44px 24px;
  }
}

.form-creating-operation__operation {
  .long {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .group__form,
  .group__additional-form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: flex-start;
    gap: 44px 24px;
  }
  .group__form[data-connected],
  .group__additional-form[data-connected] {
    display: none;
    &.is-active {
      display: grid;
    }
  }

  .group__title-subgroup {
    grid-column-start: 1;
    grid-column-end: 3;
  }
  .group--additional.border-none {
    border-top: none;
  }
}

.form-creating-operation__complications,
.complication {
  .group__form,
  .group__additional-form {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: flex-start;
    gap: 44px 24px;
  }

  .group--additional:first-child {
    border-top: 0;
  }
}

.form-creating-operation__complication {
  .btn {
    justify-self: self-start;
    align-self: center;
  }
  .textarea {
    grid-column-end: span 2;
  }
}

.form-creating-operation__intraoperative,
.form-creating-operation__emergency-situations {
  .group__form {
    grid-template-columns: repeat(3, 1fr);
  }
  .long-input {
    grid-column-start: 2;
    grid-column-end: 4;
  }
}

.form-creating-operation__hospital {
  .group__form {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: flex-start;
    gap: 44px 24px;
  }
}

.form-creating-operation__additional-info {
  .textarea {
    margin-bottom: 44px;
  }
}

@media (max-width: 1400px) {
  .form-creating-operation__general-information {
    .group__form {
      grid-template-columns: repeat(3, 1fr);
    }
    .long-input {
      grid-column-start: 1;
      grid-column-end: 4;
    }
  }
}

@media (max-width: $desktop) {
  .form-creating-operation__general-information,
  .form-creating-operation__operation,
  .form-creating-operation__complications,
  .form-creating-operation__complication,
  .form-creating-operation__emergency-situations,
  .form-creating-operation__additional-info,
  .form-creating-operation__hospital {
    .group__form,
    .group__additional-form {
      grid-template-columns: repeat(2, 1fr);
    }
    .long-input {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }

  .complication {
    .group__form,
    .group__additional-form {
      grid-template-columns: repeat(2, 1fr);
    }
    .long-input {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }
}

@media (max-width: $mobile-big) {
  .form-creating-operation__general-information,
  .form-creating-operation__operation,
  .form-creating-operation__complications,
  .form-creating-operation__complication,
  .form-creating-operation__emergency-situations,
  .form-creating-operation__additional-info,
  .form-creating-operation__hospital {
    .group__form,
    .group__additional-form {
      grid-template-columns: repeat(1, 1fr);
    }
    .long-input {
      grid-column-start: 1;
      grid-column-end: 2;
    }
  }

  .complication {
    // .group__header {
    //   flex-direction: column;
    // }
    .group__title {
      flex-direction: column;
    }

    .group__add-info {
      align-self: start;
      margin: 0;
    }

    .group__buttons {
      align-items: end;
      flex-direction: column-reverse;
    }

    .group__form,
    .group__additional-form {
      grid-template-columns: repeat(1, 1fr);
    }
    .long-input {
      grid-column-start: 1;
      grid-column-end: 2;
    }
  }

  .form-creating-operation__operation {
    .group__title-subgroup {
      grid-column-start: 1;
      grid-column-end: auto;
    }

    & > .group__header {
      .group__title {
        flex-wrap: wrap;
      }

      .group__add-info {
        margin: 0;
      }
    }
  }

  .form-creating-operation__complications {
    & > .group__header {
      .group__subtitle {
        display: none;
      }
    }
  }
}
