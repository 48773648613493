.side-modal.i-modal {
  overflow: hidden;
  z-index: 50;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  padding: 0;
  opacity: 1;
  transition-property: transform;
  transform: translateX(100%);
  background-color: transparent;

  &.is-active {
    opacity: 1;
    visibility: visible;
    z-index: 50;
    transform: translateX(0);

    .i-modal__content {
      opacity: 1;
    }
  }

  &.view-mode {
    .side-modal__bottom {
      display: none;
    }

    .i-modal__inner {
      padding-bottom: 0;
    }
  }

  .i-modal__content {
    max-width: 940px;
    opacity: 1;
    margin: 0;
    margin-left: auto;
    padding: 0;
    padding-top: 96px;
    width: 100%;
    height: 100%;
    border-radius: 0;
    transform: none;
  }

  .i-modal__inner {
    position: relative;
    height: 100%;
    // overflow: auto;
    // overflow-x: hidden;
  }

  form {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .side-modal__header {
    position: sticky;
    z-index: 2;
    top: 0;
    background-color: $white;
    left: 0;
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 24px;

    // border-bottom: 2px solid #e8eaee;

    padding-bottom: 24px;

    &::before {
      content: '';
      display: block;
      width: calc(100% - 80px);
      height: 2px;
      position: absolute;
      background-color: #e8eaee;
      bottom: 0;
      left: 40px;
    }

    &.is-scrolled {
      margin-bottom: 0;
      &::before {
        display: none;
      }
    }
  }

  .side-modal__add-txt {
    display: none;
    align-items: center;
    column-gap: 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
    margin-bottom: 8px;
    color: $grey;
    cursor: pointer;

    svg {
      width: 18px;
      height: 18px;
      stroke: $grey;
      fill: none;
      transform: rotate(90deg);
    }
  }

  .side-modal__header-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .side-modal__header-buttons {
    display: flex;
    align-items: center;
    column-gap: 16px;

    .btn {
      display: none;
    }
  }

  .btn.side-modal__close {
    display: inline-flex;
  }

  .side-modal__remove {
    svg {
      stroke: $red-btn;
    }
  }

  .side-modal__title {
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
    color: $black;
  }

  .side-modal__main {
    position: relative;
    padding-top: 24px;
    padding-left: 40px;
    padding-right: 40px;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    // grid-template-columns: repeat(2, 1fr);
    // gap: 44px 24px;
    overflow: auto;
    height: calc(100% - 174px);
  }

  .side-modal__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 44px 24px;
  }

  .side-modal__bottom {
    position: absolute;
    left: 0;
    z-index: 2;
    bottom: 0;
    padding: 24px 40px;
    background-color: $white;
    border-top: 2px solid #e8eaee;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 20px;

    .btn {
      max-width: 240px;
      width: 100%;
    }
  }

  .long {
    grid-column-end: span 2;
  }
}

// Модальное окно операции
.side-modal[data-side-modal-name='operation'],
.side-modal[data-side-modal-name='observation'],
.side-modal[data-side-modal-name='patient'] {
  .file-loader {
    padding: 0 40px;
  }

  .side-modal__main {
    height: 100%;
  }

  &.is-editable {
    .group__form,
    .group__additional-form {
      gap: 44px 24px;
    }

    .side-modal__main {
      height: calc(100% - 174px);
    }

    .group__header {
      padding-bottom: 44px;
    }

    .side-modal__show-required {
      display: inline-flex;
    }

    .group__title-subgroup {
      color: $violet;
    }
  }

  .side-modal__main {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    padding: 0;
    padding-top: 24px;
  }

  .group__title-subgroup {
    color: $black;
    grid-column-start: 1;
    grid-column-end: 3;

    &:last-child {
      display: none;
    }
  }

  .group__form,
  .group__additional-form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    // align-items: flex-start;
    gap: 24px;

    .data-block:only-child {
      grid-column-end: span 2;
    }
  }

  .group.three-columns {
    .group__form,
    .group__additional-form {
      grid-template-columns: repeat(3, 1fr);
    }

    .long {
      grid-column-end: span 3;
    }
  }

  .side-modal__header-buttons {
    .side-modal__edit,
    .side-modal__print,
    .side-modal__remove {
      display: inline-flex;
    }
  }

  // .side-modal__bottom {
  //   z-index: 1;
  // }

  // input
  .input-custom {
    input,
    input ~ label {
      background-color: $background;
    }
  }

  // select
  .itc-select {
    .itc-select__toggle,
    .itc-select__placeholder {
      background-color: $background;
    }
  }

  // textarea
  .textarea {
    textarea,
    label {
      background-color: $background;
    }
  }
}

.side-modal[data-side-modal-name='operation'] {
  .side-modal__header-buttons {
    .side-modal__add-file {
      display: inline-flex;
    }
  }
}

.side-modal[data-side-modal-name='observation'] {
  &.is-editable {
    .side-modal__main {
      height: calc(100% - 202px);
    }
  }
}

.side-modal[data-side-modal-name='observation'] {
  .side-modal__add-txt {
    display: flex;
  }

  &.is-editable {
    .side-modal__header-buttons {
      .side-modal__add-file {
        display: inline-flex;
      }
    }
  }
}

.side-modal[data-side-modal-name='patient'] {
  .side-modal__header-buttons {
    .side-modal__edit,
    .side-modal__print,
    .side-modal__remove {
      display: none;
    }
    .side-modal__add-file {
      display: inline-flex;
    }
    .side-modal__show-required {
      display: inline-flex;
    }
  }
}

.side-modal[data-side-modal-name='editing-assistant'] {
  .side-modal__header-buttons {
    .side-modal__remove {
      display: inline-flex;
    }
  }
}

@media (max-width: $mobile-big) {
  .side-modal.i-modal {
    .i-modal__content {
      padding-top: 60px;
    }

    .side-modal__header {
      padding: 20px;
      &::before {
        width: calc(100% - 40px);
        left: 20px;
      }
    }

    .side-modal__main {
      display: flex;
      flex-direction: column;
      row-gap: 24px;
      // grid-template-columns: 1fr;
      // align-items: flex-start;
      // justify-content: flex-start;
    }

    .group__form,
    .group__additional-form {
      grid-template-columns: 1fr;
    }

    .long {
      grid-column-end: span 1;
    }

    .side-modal__grid {
      grid-template-columns: 1fr;
      row-gap: 24px;
    }

    .side-modal__title {
      font-size: 22px;
    }

    .group__title-subgroup {
      grid-column-start: 1;
      grid-column-end: 1;
    }
  }

  .side-modal[data-side-modal-name='operation'],
  .side-modal[data-side-modal-name='observation'],
  .side-modal[data-side-modal-name='patient'] {
    .group.three-columns {
      .group__form,
      .group__additional-form {
        grid-template-columns: 1fr;
      }

      .long {
        grid-column-end: span 1;
      }
    }

    .file-loader {
      padding: 0 20px;
    }
  }
}

@media (max-width: $mobile) {
  .side-modal.i-modal {
    .side-modal__header-main {
      flex-direction: column;
      align-items: start;
      row-gap: 10px;
    }

    .side-modal__bottom {
      flex-direction: column;
      row-gap: 10px;
      padding: 10px 40px;
    }
  }
}
