.patient-card {
  &__wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    column-gap: 20px;
  }

  &__main {
    flex-grow: 1;
    width: calc(100% - 325px);
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }

  &__decor-line {
    width: 100%;
    height: 2px;
    background-color: $grey-super-light;
    margin: 24px 0;
  }

  &__bottom {
    display: flex;
    justify-content: flex-end;

    .btn {
      max-width: 325px;
      width: 100%;
    }
  }

  &__loader {
    .file-loader__add-file {
      display: none;
    }
  }

  &__files {
    &:empty {
      display: none;
    }

    display: flex;
    gap: 8px;
    flex-wrap: wrap;
  }
}

.patient-card__complications,
.patient-card__operations {
  display: flex;
  flex-wrap: wrap;
  column-gap: 16px;
  row-gap: 16px;
}
// черновой вариант
.patient-card__mgb {
  width: 237px;
}

.patient-card__files-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.chart-wrapper {
  position: relative;
  height: 286px;
  width: 100%;
}

.losing-weight-section {
  .page-section__content {
    overflow-x: auto;
  }
}

@media (max-width: $desktop) {
  .patient-card {
    &__wrapper {
      flex-direction: column;
      row-gap: 20px;
    }

    &__main {
      width: 100%;
    }
  }
}

@media (max-width: $mobile-big) {
  .patient-card {
    .chart-wrapper {
      width: 700px;
      height: 240px;
    }
  }
}
