.group-radio-buttons {
  &[data-connected] {
    display: none;
  }

  &[data-required].hightlight {
    .group-radio-buttons__title {
      animation: titleLight 3s ease 0s infinite;
    }
  }

  &.is-filled.hightlight {
    .group-radio-buttons__title {
      animation: none;
    }
  }

  &.is-invalid.hightlight {
    .group-radio-buttons__title {
      animation: titleErrorLight 3s ease 0s infinite;
    }
  }

  &.is-active {
    display: block;
  }

  &__title {
    display: flex;
    column-gap: 8px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
    margin-bottom: 8px;
    color: $grey;
  }
  &__options {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;

    label {
      white-space: nowrap;
    }
  }
}

@keyframes titleLight {
  0% {
    color: $grey;
  }
  50% {
    color: $violet;
  }
  100% {
    color: $grey;
  }
}

@keyframes titleErrorLight {
  0% {
    color: $input-error;
  }
  50% {
    color: $input-error;
  }
  100% {
    color: $input-error;
  }
}
