.calendar {
  container-type: inline-size;
  z-index: 10;
  min-width: 306px;
  // min-width: none;
  width: 100%;
  background: $background-violet;
  border-radius: 4px;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
  @include transition('opacity, visibility');

  &.absolute {
    position: absolute;
    top: calc(100% + 2px);
    opacity: 0;
    visibility: hidden;

    left: 0;

    &.is-active {
      opacity: 1;
      visibility: visible;
    }
  }

  &.right-side {
    left: auto;
    right: 0;
  }

  .btn {
    width: 24px;
    height: 24px;

    svg {
      fill: $grey;
      stroke: none;
    }
  }

  .itc-select {
    .itc-select__toggle,
    .itc-select__placeholder {
      background: $background-violet !important;
    }
  }
}

.calendar__header {
  padding: 20px 12px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 35px;
}

.calendar__body {
  padding: 0 12px 4px;
  ul {
    padding: 0;
    list-style: none;
    // display: flex;
    // flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(7, 1fr);

    li {
      position: relative;
      // width: calc(100% / 7);
      height: 48px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $grey-dark;
    }
  }
}

.calendar__months,
.calendar__years {
  display: flex;
  align-items: center;
  column-gap: 12px;
}

.calendar-toggler {
  text-align: center;
  border-radius: 50%;
  position: absolute;
  cursor: pointer;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  @include transition('background');

  svg {
    max-width: 24px;
    width: 100%;
    height: 100%;
    fill: none;
    stroke: $violet;
  }

  @media (hover: hover) {
    &:hover {
      background-color: rgba($grey, 0.12);
    }
  }
}

.calendar__dates {
  li {
    cursor: pointer;

    &.inactive {
      color: $grey-light;
    }

    &.active {
      color: $white;
    }

    &.active:before {
      background-color: $violet;
    }

    &:not(.active):hover::before {
      background-color: $grey-super-light;
    }

    &:before {
      content: '';
      width: 40px;
      height: 40px;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;
    }
  }
}

@container (max-width: 360px) {
  .calendar {
    min-width: none;
  }

  .calendar__header {
    column-gap: 12px;
  }

  .calendar__months,
  .calendar__years {
    column-gap: 6px;
  }
}

@media (max-width: $mobile-big) {
  .calendar {
    min-width: 250px;
  }
}
