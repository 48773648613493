.patient-table {
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  &.is-empty {
    .patient-table__body {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 84px 0;
    }

    .patient-table__header {
      top: 0;
      position: relative;
    }

    .patient-table__empty {
      display: inline-flex;
    }
  }

  &__header {
    border-radius: 0 0 10px 10px;
    position: sticky;
    padding-top: 24px;
    z-index: 5;
    top: 95px;
    background-color: $background;

    &.shadow {
      .patient-table__header-inner {
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
      }
    }

    &-inner {
      background-color: $white;
      padding: 16px 32px;
      border-radius: 20px 20px 10px 10px;
      display: grid;
      grid-template-columns: minmax(auto, 231px) 72px 45px 83px minmax(auto, 231px) 92px 101px minmax(auto, 129px) 93px 40px;
      column-gap: 20px;
    }
  }

  &__body {
    padding: 16px 32px;
    border-radius: 10px 10px 20px 20px;
    background-color: $white;
  }

  &__empty {
    display: none;
    align-items: center;
    flex-direction: column;

    & > span {
      display: block;
      margin-bottom: 8px;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 26px;
    }

    .btn {
      margin-bottom: 12px;
    }

    img {
      width: 170px;
      height: 122px;
      object-fit: cover;
    }
  }
}

.parametr {
  display: inline-flex;
  align-items: center;
  column-gap: 4px;

  &.is-up {
    path.up {
      stroke: $violet;
    }
  }

  &.is-down {
    path.down {
      stroke: $violet;
    }
  }

  &__name {
    cursor: pointer;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    white-space: nowrap;
    letter-spacing: 0.1px;
    color: $grey-light;
  }

  svg {
    fill: none;
    stroke: $grey-light;
  }
}

.patient {
  position: relative;
  cursor: pointer;
  display: grid;
  grid-template-columns: minmax(auto, 231px) 72px 45px 83px minmax(auto, 231px) 92px 101px minmax(auto, 129px) 93px 40px;
  align-items: center;
  column-gap: 20px;
  padding-bottom: 16px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: $grey-super-light;
    height: 1px;
    border-radius: 20px;
  }

  &__param {
    // txt
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.1px;
  }

  .event {
    display: block;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: $grey-light;
  }

  &__age,
  &__gender,
  &__IMT {
    text-align: center;
  }
  &__operations {
    display: flex;
    flex-wrap: wrap;
    gap: 8px 4px;
  }

  &__actions {
    position: relative;
    cursor: pointer;
    width: 40px;
    height: 40px;
    padding: 8px;
    background-color: $background;
    border-radius: 50%;

    .dots-icon {
      width: 100%;
      height: 100%;
      fill: $grey;
      stroke: none;
      // stroke: $grey;
    }
  }
}

@media (max-width: $mobile-big) {
  .patient-table {
    &__header {
      top: 67px;
    }
  }
}
