.filters {
  position: relative;

  &.is-active {
    .filters-tooltip {
      opacity: 1;
      visibility: visible;
    }
  }

  &__btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    column-gap: 8px;

    // font
    white-space: nowrap;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.1px;
    color: $violet;

    svg {
      width: 18px;
      height: 18px;
      stroke: $violet;
      fill: none;
    }

    @media (hover: hover) {
      &:hover {
        opacity: 0.85;
      }
    }

    &:active {
      opacity: 0.75;
    }
  }
}

.filters-tooltip {
  @include transition('opacity, visibility');
  position: absolute;
  opacity: 0;
  visibility: hidden;
  z-index: 10;
  width: 460px;
  height: 420px;
  top: 100%;
  right: -100px;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
  background-color: $white;
  padding: 16px;
  padding-right: 4px;
  border-radius: 8px;

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    padding-right: 12px;

    & > span {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px; /* 150% */
      letter-spacing: 0.15px;
      color: $grey-dark;
    }
  }

  &__body {
    height: calc(100% - 24px);
    padding-right: 8px;
    overflow: auto;
    @include scrollbar(4px, transparent, $grey-light);
    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
    }
  }

  &__section {
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    padding-bottom: 16px;
    &:not(:last-child) {
      margin-bottom: 16px;

      &::before {
        display: block;
      }
    }

    &::before {
      display: none;
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background-color: $grey-super-light;
      height: 1px;
      border-radius: 20px;
    }
  }

  &__section-name {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.1px;
    color: $grey-light;
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 8px 4px;
  }

  &__calendars {
    height: 48px;
    display: flex;
    column-gap: 12px;

    .input-custom {
      flex-grow: 1;
    }

    .vertical-line {
      width: 1px;
      height: 100%;
      background-color: $grey-super-light;
    }
  }

  &__btns {
    display: flex;
    align-items: center;
    column-gap: 10px;
  }

  &__close {
    // display: none;
    padding: 0;
    background: none;
    border: none;
    width: 24px;
    height: 24px;

    svg {
      pointer-events: none;
      width: 100%;
      height: 100%;
      stroke: $grey-light;
    }
  }
}

@media (max-width: $mobile-big) {
  .filters {
    position: static;
  }
  .filters-tooltip {
    border-radius: 0;
    right: auto;
    width: 100%;
    height: calc(100vh - 60px);
    top: 60px;
    left: 0;

    &__section {
      row-gap: 16px;
    }

    &__close {
      display: block;
    }
  }
}
