.control-panel {
  &__tools {
    display: flex;
    justify-content: space-between;
  }

  &__btns {
    display: flex;
    align-items: center;
    column-gap: 16px;
  }

  &__active-filters {
    @include transition('margin');
    display: flex;
    flex-wrap: wrap;
    gap: 8px;

    &:not(:empty) {
      margin-top: 24px;
    }
  }
}

@media (max-width: $mobile-big) {
  .control-panel {
    &__tools {
      flex-direction: column;
      row-gap: 24px;
    }
  }
}
