.i-modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  // backdrop-filter: blur(8px);
  overflow: auto;
  transition: 0.3s ease-in-out;
  transition-property: opacity, visibility, z-index;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  -webkit-overflow-scrolling: touch;
  padding: 34px;

  &.is-active {
    opacity: 1;
    visibility: visible;
    z-index: 1001;

    .i-modal__content {
      opacity: 1;
      transform: translateY(0) scale(1);
    }
  }

  &.i-modal {
    &--2deep {
      z-index: 1002;
      transition-property: opacity, visibility;
    }

    &--3deep {
      z-index: 1003;
      transition-property: opacity, visibility;
    }
  }

  &__close {
    width: 36px;
    height: 36px;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    z-index: 2;
    transition: 0.25s linear;
    transition-property: background;
    color: slategray;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      transition: 0.25s linear;
      transition-property: fill, stroke, color;
    }
  }

  &__content {
    max-width: 744px;
    width: 100%;
    margin: auto;
    border-radius: 20px;
    background-color: #fff;
    position: relative;
    padding: 44px 32px;
    opacity: 0;
    transform: translateY(-20%) scale(0.8);
    transition: all 0.3s ease-in-out;
  }

  &__title {
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
    position: relative;
    text-align: center;
    margin-bottom: 24px;
  }

  &__subtitle {
    text-align: center;
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 24px;
  }
}

@media (max-width: $mobile-big) {
  .i-modal {
    padding: 20px;
    &__content {
      padding: 30px 20px;
    }
  }
}

// Стили стандартного окна
.modal-style {
  .i-modal__content {
    max-width: 670px;
  }
  &__title {
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
    color: $black;
    text-align: center;
  }

  &__subtitle {
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: $grey-light;
    margin-bottom: 24px;

    br {
      display: none;
    }
  }

  &__buttons {
    margin-top: 44px;
    display: flex;
    justify-content: space-between;
    column-gap: 20px;
    .btn {
      width: auto;
      flex-grow: 1;
    }
  }
}

@media (max-width: $mobile-big) {
  .modal-style {
    &__subtitle {
      br {
        display: block;
      }
    }
  }
}
