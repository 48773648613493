.modal-result-query {
  .i-modal__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 16px;
  }

  &.rejected {
    img {
      display: none;
    }
  }

  &__title {
    margin: 0;
  }

  img {
    display: block;
    width: 130px;
    height: 130px;
  }
}
