.header {
  @include transition('padding');
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  box-shadow: 0px 8px 44px 0px rgba(0, 0, 0, 0.04);

  padding-top: 24px;
  padding-bottom: 24px;
  background-color: $white;

  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 40px;
  }

  &__logo {
    width: 48px;
    height: 48px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__nav {
    display: flex;
    flex-grow: 1;
    column-gap: 24px;
  }

  &__nav-el {
    // txt
    color: $violet-deep;
    font-size: 20px;
    cursor: pointer;
    font-weight: 500;
    line-height: 26px;
    @include transition('color');

    @media (hover: hover) {
      &:hover {
        color: $violet;
      }
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    column-gap: 20px;
  }

  &__mobile-menu {
    display: none;
  }
}

.burger {
  cursor: pointer;

  .burger-line {
    @include transition(transform);
    width: 25px;
    display: block;
    height: 2px;
    background-color: $violet;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &.is-active {
    .burger-line {
      background-color: $grey-light;
    }
    .burger-line:nth-child(1) {
      transform: rotate(-45deg) translateY(5px) translateX(0);
    }

    .burger-line:nth-child(2) {
      transform: rotate(45deg) translateY(-5px) translateX(0);
    }

    .burger-line:nth-child(3) {
      display: none;
    }
  }
}

.home {
  .header {

    &__logo {
      display: none;
    }

    &__sign-in-btn {
      width: 325px;
    }

    &__registration-btn {
      width: 220px;
    }
  }
}

@media (max-width: $tablet) {
  .home {
    main {
      padding-top: 60px;
    }

    .logo-full__logo {
      display: none;
    }

    .header {
      padding: 12px 0;

      .header__logo {
        display: block;
      }

      &__sign-in-btn {
        width: 180px;
      }
  
      &__registration-btn {
        display: none;
      }

      &__inner {
        grid-template-columns: 48px auto;
      }

      &__buttons {
        justify-content: flex-end;
      }
    }
  }

  .header {
    padding: 10px 0;

    &.menu-is-open {
      .header__nav {
        transform: translateX(0);
      }
    }

    &__inner {
      width: 100%;
      display: grid;
      align-items: center;
      justify-content: initial;
      column-gap: 15px;
      grid-template-columns: 48px auto 25px;
    }

    &__logo {
      flex-grow: 0;
      width: 40px;
      height: 40px;
    }

    &__nav {
      @include transition(transform);
      transform: translateX(100%);
      position: absolute;
      background-color: $background;
      right: 0;
      z-index: 70;
      top: 60px;

      height: calc(100vh - 60px);
      box-shadow: 8px 10px 20px 0px rgba(0, 0, 0, 0.1);
      flex-direction: column;
      row-gap: 10px;
      width: 250px;
      padding: 20px;
    }

    &__nav-el {
      font-size: 18px;
    }

    &__mobile-menu {
      display: block;
      justify-self: end;
    }

    .user {
      flex-grow: 0;
      justify-self: end;
    }
  }
}

@media (max-width: $mobile-big) {
  .header {
    &__nav {
      flex-grow: 0;
      display: inline-flex;
    }

    .header__buttons {
      justify-content: flex-end;
      column-gap: 15px;
    }

    &.is-blocked {
      pointer-events: none;
    }
  }
}

@media (max-width: $mobile) {
  .header {
    &__nav-el {
      font-size: 16px;
    }
  }
}
