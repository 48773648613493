.table-wrapper {
  overflow-x: auto;
  padding-bottom: 5px;
}

.concomitant-diseases {
  &__table {
    width: max-content;
  }

  &__title,
  &__name {
    padding: 10px 16px;
    text-align: center;
    // txt
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.5px;
    color: $grey-light;

    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__title:first-child,
  &__name {
    text-align: end;
  }

  &__title {
    width: 150px;
    display: inline-block;
    flex-shrink: 0;

    br {
      display: none;
    }
  }

  &__title:first-child {
    position: sticky;
    left: 0;
    z-index: 1;
    width: 138px;
    background: $white;
  }

  &__row {
    white-space: nowrap;
    display: flex;
    width: 100%;
    // width: max-content;
    align-items: center;
    gap: 12px;

    &:not(:last-of-type) {
      margin-bottom: 12px;
    }
  }

  &__diseases {
    column-gap: 12px;
  }

  &__name {
    width: 138px;
    display: inline-block;
    position: sticky;
    left: 0;
    z-index: 1;
    flex-shrink: 0;
    color: $grey-dark;
    background: $white;
  }

  &__changes {
    column-gap: 12px;
  }
}

.concomitant-diseases__tab {
  width: 150px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  column-gap: 4px;
  padding: 8px 16px;
  border-radius: 10px;
  // txt
  white-space: nowrap;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: $grey-dark;

  svg {
    width: 16px;
    height: 16px;
    fill: none;
    stroke: $grey-dark;
    opacity: 0.4;
  }
  &.tab-exist {
    background-color: $beige;
  }

  &.tab-no-changes {
    background-color: $beige;
  }

  &.tab-decline {
    background-color: $red;
  }

  &.tab-improvement {
    background-color: $green-light;
  }

  &.tab-no {
    background-color: $grey-200;
  }
}

@media (max-width: $tablet) {
  .concomitant-diseases__tab {
    padding: 4px;
  }
}

@media (max-width: $mobile-big) {
  .concomitant-diseases {
    &__name,
    &__title {
      padding: 5px;
    }

    &__row {
      &:not(:last-of-type) {
        margin-bottom: 8px;
      }
    }
  }
}

@media (max-width: $mobile) {
  .concomitant-diseases {
    &__title {
      br {
        display: block;
      }
    }
  }
}
