@charset "UTF-8";
/*!*
 * ВНИМАНИЕ! Этот файл генерируется автоматически.
 * Любые изменения этого файла будут потеряны при следующей компиляции.
 * Любое изменение проекта без возможности компиляции ДОЛЬШЕ И ДОРОЖЕ в 2-5 раз.
 */
@import "tippy.js/dist/tippy.css";
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Inter/Inter_24pt-Regular.woff") format("woff"), url("../fonts/Inter/Inter_24pt-Regular.ttf") format("truetype"), url("../fonts/Inter/Inter_24pt-Regular.eot"), url("../fonts/Inter/Inter_24pt-Regular.eot?#iefix") format("embedded-opentype");
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/Inter/Inter_24pt-Medium.woff") format("woff"), url("../fonts/Inter/Inter_24pt-Medium.ttf") format("truetype"), url("../fonts/Inter/Inter_24pt-Medium.eot"), url("../fonts/Inter/Inter_24pt-Medium.eot?#iefix") format("embedded-opentype");
}
@keyframes shadowViolet {
  0% {
    box-shadow: 0px 0px 0px 0px #675dda;
  }
  50% {
    box-shadow: 0px 0px 0px 1px #675dda;
  }
  100% {
    box-shadow: 0px 0px 0px 0px #675dda;
  }
}
@keyframes shadowRed {
  0% {
    box-shadow: 0px 0px 0px 0px #b3261e;
  }
  50% {
    box-shadow: 0px 0px 0px 1px #b3261e;
  }
  100% {
    box-shadow: 0px 0px 0px 0px #b3261e;
  }
}
/* smartphones, touchscreens */
/* stylus-based screens */
/* Nintendo Wii controller, Microsoft Kinect */
/* mouse, touch pad */
/* hide scrollbar */
* {
  -webkit-overflow-scrolling: touch;
  scrollbar-color: #79747e transparent;
  scrollbar-width: thin;
}
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: transparent;
}
::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:vertical {
  background-color: #79747e;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:horizontal {
  background-color: #79747e;
  border-radius: 10px;
}
::-webkit-scrollbar-track, ::-webkit-scrollbar-thumb {
  border-radius: 10px;
}

@-ms-viewport {
  width: device-width;
}
[data-whatintent=touch] *:focus,
[data-whatintent=mouse] *:focus {
  outline: none;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

*::before,
*::after {
  box-sizing: inherit;
}

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-size-adjust: 100%;
}

body {
  position: relative;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #000000;
  background-color: #f4f3ff;
  overflow-y: visible;
  text-align: left;
  cursor: default;
  word-break: break-word;
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media (max-width: 767px) {
  body {
    font-size: 14px;
  }
}

[tabindex="-1"]:focus {
  outline: none !important;
}

[role=button] {
  cursor: pointer;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

a,
button,
[role=button],
input,
label,
select,
textarea {
  touch-action: manipulation;
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

button,
input {
  overflow: visible;
  text-transform: none;
}

button,
html [type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

button {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
}

input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
  -webkit-appearance: listbox;
}

input[type=submit] {
  width: auto;
}

input[type=number] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type=search] {
  -webkit-appearance: textfield;
}

input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

textarea {
  overflow: auto;
  resize: vertical;
}

svg {
  fill: currentColor;
}
a svg, button svg {
  pointer-events: none;
}
svg:not(:root) {
  overflow: hidden;
}

img {
  max-width: 100%;
  height: auto;
  border: none;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  margin-top: 2em;
  margin-bottom: 2em;
  border: 0;
  border-top: 1px solid hsl(0, 0%, 70%);
}

p,
ul,
ol,
dl,
table,
blockquote,
pre,
address,
figure {
  margin-top: 0;
  margin-bottom: 0;
}

p {
  hyphens: auto;
  hyphenate-limit-chars: 6 3 3;
  hyphenate-limit-lines: 2;
  hyphenate-limit-last: always;
  hyphenate-limit-zone: 8%;
  padding: 0;
  margin: 0;
}

address {
  font-style: normal;
}

ul,
ol {
  margin: 0;
}
ul.no-style,
ol.no-style {
  padding: 0;
  list-style: none;
}

li {
  line-height: inherit;
}

blockquote {
  padding: 0;
  margin: 0;
}

code {
  padding: 0.06em 0.3em;
  color: #79747e;
  background-color: rgba(0, 0, 0, 0.08);
  border-radius: 3px;
}

kbd {
  padding: 0.06em 0.3em;
  color: #79747e;
  background-color: hsl(0, 0%, 90%);
  border-radius: 3px;
}
kbd kbd {
  padding: 0;
}

pre {
  display: block;
  width: 100%;
  margin-bottom: 0;
  overflow-x: auto;
  tab-size: 2;
  background-color: rgba(0, 0, 0, 0.08);
}
pre code {
  background: transparent;
}

[contenteditable=true]:empty:before {
  content: attr(data-placeholder);
  color: #131313;
  font-size: inherit;
  display: block; /* For Firefox */
}

select {
  appearance: none;
}
select::-ms-expand {
  display: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  color: currentColor;
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 70px;
  font-weight: 500;
  line-height: 77px;
}

h2 {
  font-size: 42px;
  line-height: 51px;
  font-weight: 700;
}

h3 {
  font-size: 28px;
  line-height: 34px;
}

h4 {
  font-size: 22px;
}

.container {
  max-width: 1440px;
  width: 100%;
  margin: auto;
  padding: 0 40px;
}
@media (max-width: 575px) {
  .container {
    padding: 0 20px;
  }
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.apple-fix {
  width: 100%;
  position: fixed;
}

.no-scroll {
  overflow: hidden;
}

.clearfix::after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  position: relative;
}
.wrapper main {
  padding-top: 96px;
}
.wrapper.new-patient main {
  padding-bottom: 92px;
}
.wrapper.patient-card main {
  padding-bottom: 67px;
}
.wrapper.profile main {
  padding-bottom: 52px;
}
.wrapper.creating-operation main {
  padding-bottom: 92px;
}
.wrapper.patient-list main {
  padding-bottom: 40px;
}
.wrapper.patient-list .table-container {
  min-width: 1440px;
}
.wrapper.support main {
  padding-bottom: 60px;
}
.wrapper.login.is-success .register-form,
.wrapper.login.is-success .login-form,
.wrapper.login.is-success .recovery-password-form, .wrapper.register.is-success .register-form,
.wrapper.register.is-success .login-form,
.wrapper.register.is-success .recovery-password-form, .wrapper.recovery.is-success .register-form,
.wrapper.recovery.is-success .login-form,
.wrapper.recovery.is-success .recovery-password-form {
  display: none;
}
.wrapper.login.is-success .success-block, .wrapper.register.is-success .success-block, .wrapper.recovery.is-success .success-block {
  display: flex !important;
}
.wrapper.login main, .wrapper.register main, .wrapper.recovery main {
  padding: 0;
  background-image: url("../img/bg-bareo.jpg");
  background-size: cover;
}
.wrapper.sticky {
  overflow: unset;
}
.wrapper .header {
  flex: 0 0 auto;
}
.wrapper main {
  position: relative;
  flex: 1 0 auto;
}
.wrapper .footer {
  flex: 0 0 auto;
}

.is-default-hidden {
  display: none !important;
}

.tippy-box[data-theme~=violet] {
  background-color: #675dda;
  color: #ffffff;
}

.tippy-box[data-theme~=violet][data-placement^=top] > .tippy-arrow::before {
  border-top-color: #675dda;
}

.tippy-box[data-theme~=violet][data-placement^=bottom] > .tippy-arrow::before {
  border-bottom-color: #675dda;
}

.tippy-box[data-theme~=violet][data-placement^=left] > .tippy-arrow::before {
  border-left-color: #675dda;
}

.tippy-box[data-theme~=violet][data-placement^=right] > .tippy-arrow::before {
  border-right-color: #675dda;
}

.svg {
  flex-shrink: 0;
  fill: none;
  width: 100%;
  height: 100%;
}

.hidden-input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.tooltip-menu {
  transition: 0.25s linear;
  transition-property: opacity, visibility;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  top: 100%;
  right: 0;
  z-index: 5;
  height: auto;
  padding: 8px 0;
  list-style: none;
  background: linear-gradient(0deg, rgba(103, 80, 164, 0.08), rgba(103, 80, 164, 0.08)), #fffbfe;
  border-radius: 4px;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
}
.tooltip-menu.is-active {
  opacity: 1;
  visibility: visible;
}

.tooltip-menu__option {
  cursor: pointer;
  min-width: 144px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 16px;
  padding: 12px 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #1c1b1f;
  white-space: nowrap;
}
@media (hover: hover) {
  .tooltip-menu__option:hover {
    background-color: rgba(103, 93, 218, 0.1);
  }
}
.tooltip-menu__option.disabled {
  opacity: 0.5;
  pointer-events: none;
}
.tooltip-menu__option.disabled.loading {
  opacity: 1;
}
.tooltip-menu__option.loading svg {
  display: none;
}
.tooltip-menu__option.logout {
  color: #dc362e;
}
.tooltip-menu__option.logout svg {
  stroke: #dc362e;
}
.tooltip-menu__option svg {
  transition: 0.25s linear;
  transition-property: stroke;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  fill: none;
  stroke: #49454f;
}

.login-logo {
  margin: 0 auto;
  margin-bottom: 40px;
}

.success-block {
  display: none !important;
  margin: 0 auto;
}

.login-container {
  padding-top: 40px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  row-gap: 80px;
}

.home-container {
  padding-top: 52px;
}
@media (max-width: 575px) {
  .home-container {
    padding: 24px 12px 0;
  }
}

@media (max-width: 767px) {
  .wrapper main {
    padding-top: 60px;
  }
}
@media (max-width: 575px) {
  .wrapper.new-patient main {
    padding-bottom: 40px;
  }
  .group__delete span {
    display: none;
  }
}
.response-message {
  font-weight: 500;
  opacity: 0;
  transition: 0.25s linear;
  transition-property: opacity;
}
.response-message.is-active {
  opacity: 1;
}
.response-message.error {
  color: #e60000;
}
.response-message.success {
  color: #52b530;
}

@media only print {
  .body {
    overflow: visible !important;
  }
  .header,
  .div[data-tippy-root] {
    display: none !important;
  }
  .group__header {
    padding-bottom: 20px !important;
  }
  .group__form {
    padding-bottom: 0 !important;
  }
  .i-modal {
    position: relative !important;
    height: auto !important;
    bottom: auto !important;
  }
  .i-modal__content {
    padding-top: 0 !important;
  }
  .side-modal__header-buttons,
  .side-modal__bottom,
  .group__delete,
  .add-intervention,
  .group__completed {
    display: none !important;
  }
  .side-modal__main {
    padding-top: 0 !important;
    width: auto !important;
    height: auto !important;
    overflow: visible !important;
  }
  .side-modal__main .group__toggle {
    display: none;
  }
  .i-modal__content {
    height: auto !important;
  }
  .file-loader {
    display: none !important;
  }
  .patient-card .hero__back,
  .patient-card .general-info__actions,
  .patient-card .page-section__actions,
  .patient-card .patient-card__bottom {
    display: none;
  }
}
.btn {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;
  height: 40px;
  border: 1px solid #675dda;
  border-radius: 100px;
  cursor: pointer;
  color: #ffffff;
  background-color: #675dda;
  margin: 0;
  padding: 0 24px;
  z-index: 1;
  transition: 0.25s linear;
  transition-property: opacity;
  font-family: inherit;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  user-select: none;
}
@media (hover: hover) {
  .btn:hover {
    opacity: 0.85;
  }
}
.btn:active {
  opacity: 0.75;
}
.btn.disabled, .btn[disabled] {
  opacity: 0.5;
}
.btn[data-connected] {
  display: none;
}
.btn[data-connected].is-active {
  display: inline-flex;
}
.btn svg {
  width: 18px;
  height: 18px;
  fill: none;
  stroke: #ffffff;
  transition: 0.25s linear;
  transition-property: color, stroke;
}
.btn--ghost {
  background-color: transparent;
  border-color: #79747e;
  color: #675dda;
}
.btn--ghost svg {
  stroke: #675dda;
}
.btn--text {
  justify-content: flex-start;
  background-color: transparent;
  border: none;
  height: auto;
  padding: 0;
  white-space: nowrap;
  color: #675dda;
}
.btn--text svg {
  stroke: #675dda;
}
.btn--text span {
  transition: 0.25s linear;
  transition-property: color;
}
.btn--only-icon {
  width: 24px;
  height: 24px;
  background-color: transparent;
  padding: 0;
  border: none;
}
.btn--only-icon svg {
  stroke: #675dda;
  width: 100%;
  height: 100%;
}
.btn--red {
  background-color: #dc362e;
  border-color: #dc362e;
}

.btn-circle {
  width: 40px;
  height: 40px;
  outline: none;
  background-color: #675dda;
  border: 1px solid rgba(103, 93, 218, 0.85);
  border-radius: 50%;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.25s linear;
  transition-property: opacity;
}
.btn-circle svg {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  fill: none;
  stroke: #ffffff;
}
@media (hover: hover) {
  .btn-circle:hover {
    opacity: 0.85;
  }
}
.btn-circle:active {
  opacity: 0.75;
}
.btn-circle.disabled, .btn-circle:disabled {
  pointer-events: none;
  opacity: 0.75;
}
.btn-circle--ghost {
  background-color: transparent;
  border: 1px solid #79747e;
}
.btn-circle--ghost svg {
  stroke: #79747e;
}

.calendar {
  container-type: inline-size;
  z-index: 10;
  min-width: 306px;
  width: 100%;
  background: linear-gradient(0deg, rgba(103, 80, 164, 0.08) 0%, rgba(103, 80, 164, 0.08) 100%), #fffbfe;
  border-radius: 4px;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
  transition: 0.25s linear;
  transition-property: opacity, visibility;
}
.calendar.absolute {
  position: absolute;
  top: calc(100% + 2px);
  opacity: 0;
  visibility: hidden;
  left: 0;
}
.calendar.absolute.is-active {
  opacity: 1;
  visibility: visible;
}
.calendar.right-side {
  left: auto;
  right: 0;
}
.calendar .btn {
  width: 24px;
  height: 24px;
}
.calendar .btn svg {
  fill: #49454f;
  stroke: none;
}
.calendar .itc-select .itc-select__toggle,
.calendar .itc-select .itc-select__placeholder {
  background: linear-gradient(0deg, rgba(103, 80, 164, 0.08) 0%, rgba(103, 80, 164, 0.08) 100%), #fffbfe !important;
}

.calendar__header {
  padding: 20px 12px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 35px;
}

.calendar__body {
  padding: 0 12px 4px;
}
.calendar__body ul {
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}
.calendar__body ul li {
  position: relative;
  height: 48px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1c1b1f;
}

.calendar__months,
.calendar__years {
  display: flex;
  align-items: center;
  column-gap: 12px;
}

.calendar-toggler {
  text-align: center;
  border-radius: 50%;
  position: absolute;
  cursor: pointer;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  transition: 0.25s linear;
  transition-property: background;
}
.calendar-toggler svg {
  max-width: 24px;
  width: 100%;
  height: 100%;
  fill: none;
  stroke: #675dda;
}
@media (hover: hover) {
  .calendar-toggler:hover {
    background-color: rgba(73, 69, 79, 0.12);
  }
}

.calendar__dates li {
  cursor: pointer;
}
.calendar__dates li.inactive {
  color: #79747e;
}
.calendar__dates li.active {
  color: #ffffff;
}
.calendar__dates li.active:before {
  background-color: #675dda;
}
.calendar__dates li:not(.active):hover::before {
  background-color: #e8eaee;
}
.calendar__dates li:before {
  content: "";
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

@container (max-width: 360px) {
  .calendar {
    min-width: none;
  }
  .calendar__header {
    column-gap: 12px;
  }
  .calendar__months,
  .calendar__years {
    column-gap: 6px;
  }
}
@media (max-width: 767px) {
  .calendar {
    min-width: 250px;
  }
}
.checkbox {
  display: inline-flex;
  justify-self: self-start;
  column-gap: 8px;
  align-items: flex-start;
}
.checkbox input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.checkbox .checkbox__fake {
  padding: 3px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  user-select: none;
}
.checkbox .checkbox__fake::before {
  transition: 0.25s linear;
  transition-property: border-color;
  content: "";
  display: inline-block;
  width: 18px;
  height: 18px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 2px solid #515151;
  border-radius: 2px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}
.checkbox input:focus + .checkbox__fake::before {
  border-color: #675dda;
}
.checkbox input:checked + .checkbox__fake::before {
  background-color: #675dda;
  border-color: #675dda;
  background-image: url("../img/icons/check_small.svg");
  background-repeat: no-repeat;
  background-size: 100%;
}
.checkbox input:not(:disabled):not(:checked) + .checkbox__fake:hover::before {
  border-color: #675dda;
}
.checkbox input:disabled + .checkbox__fake::before {
  opacity: 0.4;
}

.checkbox__label {
  font-weight: 400;
  line-height: 24px;
  font-size: 16px;
  color: #79747e;
  user-select: none;
  cursor: pointer;
}

.chip {
  border-radius: 8px;
  border: 1px solid #79747e;
  background-color: transparent;
  padding: 3px 8px;
  display: inline-flex;
  align-items: center;
  column-gap: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.1px;
  color: #49454f;
}
.chip svg {
  width: 18px;
  height: 18px;
  stroke: #49454f;
  fill: none;
}
.chip__remove {
  width: 18px;
  height: 18px;
  cursor: pointer;
}
@media (hover: hover) {
  .chip__remove:hover svg {
    stroke: #675dda;
  }
}
.chip--filter {
  cursor: pointer;
}
@media (hover: hover) {
  .chip--filter:hover {
    border-color: #675dda;
  }
}
.chip--filter.is-active {
  background-color: #675dda;
  color: #ffffff;
}

.custom-select {
  position: relative;
  width: 100%;
}

.data-block__title {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
}
.data-block__value {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.drop-zone.dragover label {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='#675dda' stroke-width='3' stroke-dasharray='8%2c 10' stroke-dashoffset='6' stroke-linecap='square'/%3e%3c/svg%3e");
}
.drop-zone.dragover .drop-zone__title {
  color: #675dda;
}
.drop-zone.dragover .drop-zone__title svg {
  stroke: #675dda;
}
.drop-zone input {
  opacity: 0;
  position: absolute;
  z-index: -1;
  width: 1px;
  height: 1px;
}
.drop-zone label {
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 16px;
  padding: 44px;
  width: 100%;
  height: 144px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='black' stroke-width='1' stroke-dasharray='8%2c 10' stroke-dashoffset='6' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 4px;
  transition: 0.25s linear;
  transition-property: background-image;
}
.drop-zone__title {
  cursor: pointer;
  transition: 0.25s linear;
  transition-property: color;
  display: flex;
  justify-content: center;
  column-gap: 8px;
  font-weight: 500;
  letter-spacing: 0.15px;
  color: #49454f;
}
@media (hover: hover) {
  .drop-zone__title:hover {
    color: #675dda;
  }
  .drop-zone__title:hover svg {
    stroke: #675dda;
  }
}
.drop-zone__title svg {
  transition: 0.25s linear;
  transition-property: stroke;
  fill: none;
  stroke: #49454f;
  width: 24px;
  height: 24px;
}
.drop-zone__subtitle {
  color: #7a7289;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
}
.drop-zone__uploaded-files {
  margin-top: 16px;
  display: none;
  flex-wrap: wrap;
  gap: 8px;
}
.drop-zone__uploaded-files:has(.file) {
  display: flex;
}

@media (max-width: 767px) {
  .drop-zone label {
    padding: 20px;
    justify-content: center;
    row-gap: 8px;
  }
  .drop-zone__subtitle {
    text-align: center;
  }
}
.file {
  position: relative;
  background-color: #675dda;
  padding: 8px 16px;
  border-radius: 10px;
  display: flex;
  column-gap: 8px;
  align-items: center;
  justify-content: space-between;
}
.file.uploaded .loader {
  display: none;
}
.file.uploaded .file__uploaded {
  display: flex;
}
.file.attached {
  max-width: 252px;
  width: 100%;
}
.file.attached .file__delete {
  display: flex;
}
.file.attached .loader {
  display: none;
}
.file--advanced {
  height: 52px;
  width: 280px;
  background-color: #ffffff;
  border: 1px solid #49454f;
  padding: 8px 16px 8px 8px;
}
.file--advanced.is-active .file__menu {
  opacity: 1;
  visibility: visible;
}
.file--advanced.is-active .file__carret .file__carret-arrow {
  transform: rotate(0);
}
.file--advanced .file__name-wrapper {
  max-width: calc(100% - 26px);
}
.file--advanced .file__icon {
  width: 36px;
  height: 36px;
}
.file--advanced .file__icon svg {
  stroke: #79747e;
}
.file--advanced .file__name {
  color: #1c1b1f;
}
.file--advanced .file__type {
  color: #49454f;
}
.file--advanced .file__carret {
  display: block;
}
.file--advanced .file__image {
  width: 36px;
  height: 36px;
  border-radius: 6px;
  overflow: hidden;
}
.file--advanced .loader {
  display: none;
}
.file__name-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 8px;
}
.file__info {
  max-width: 75%;
  flex-grow: 1;
}
.file__name {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  white-space: nowrap;
  color: #ffffff;
}
.file__type {
  max-width: 172px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #d0bcff;
}
.file__icon {
  flex-shrink: 0;
  width: 44px;
  height: 44px;
}
.file__icon svg {
  width: 100%;
  height: 100%;
  fill: none;
  stroke: #ffffff;
}
.file__delete {
  position: absolute;
  cursor: pointer;
  display: none;
  align-items: center;
  justify-content: center;
  top: 8px;
  right: 8px;
  width: 12px;
  height: 12px;
}
.file__delete svg {
  pointer-events: none;
  fill: none;
  stroke: #ffffff;
  width: 100%;
  height: 100%;
}
.file__carret {
  flex-shrink: 0;
  cursor: pointer;
  position: relative;
  display: none;
  width: 18px;
  height: 18px;
}
.file__carret svg {
  width: 100%;
  height: 100%;
  fill: none;
  stroke: #79747e;
}
.file__carret .file__carret-arrow {
  transition: 0.25s linear;
  transition-property: transform;
  transform: rotate(180deg);
}
.file__uploaded {
  display: none;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: #ffffff;
  border-radius: 50%;
}
.file__uploaded svg {
  fill: none;
  width: 65%;
  height: 65%;
  stroke: #52b530;
}
.file__img {
  flex-shrink: 0;
  width: 36px;
  height: 36px;
  border: 1px solid #ffffff;
  border-radius: 6px;
  object-fit: cover;
}
.file__menu {
  transition: 0.25s linear;
  transition-property: opacity, visibility;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  top: 100%;
  left: 0;
  z-index: 5;
  height: auto;
  padding: 8px 0;
  list-style: none;
  background: linear-gradient(0deg, rgba(103, 80, 164, 0.08), rgba(103, 80, 164, 0.08)), #fffbfe;
  border-radius: 4px;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
}
.file__menu-item {
  cursor: pointer;
  min-width: 144px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 16px;
  padding: 12px 16px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #1c1b1f;
  white-space: nowrap;
}
.file__menu-item:has(a) {
  display: block;
  padding: 0;
}
.file__menu-item:has(a) a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 16px;
  padding: 12px 16px;
}
@media (hover: hover) {
  .file__menu-item:hover svg {
    stroke: #675dda;
  }
}
.file__menu-item svg {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  color: #49454f;
}

.loader {
  width: 24px;
  height: 24px;
  border: 2px solid #ffffff;
  border-bottom-color: #52b530;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media (max-width: 767px) {
  .file__menu {
    left: auto;
    right: 0;
  }
}
.file-loader {
  margin-bottom: 4px;
  display: flex;
  width: 100%;
  gap: 8px;
  flex-wrap: wrap;
}
.file-loader__content {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}
.file-loader__content:empty {
  display: none;
}
.file-loader__add-file {
  cursor: pointer;
  width: 52px;
  height: 52px;
  padding: 17px;
  border-radius: 10px;
  border: 1px dashed #675dda;
  display: flex;
  align-items: center;
  justify-content: center;
}
.file-loader__add-file input[type=file] {
  display: none;
}
.file-loader__add-file svg {
  flex-shrink: 0;
  width: 18px;
  height: 18px;
  stroke: #675dda;
}

.filters {
  position: relative;
}
.filters.is-active .filters-tooltip {
  opacity: 1;
  visibility: visible;
}
.filters__btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  column-gap: 8px;
  white-space: nowrap;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.1px;
  color: #675dda;
}
.filters__btn svg {
  width: 18px;
  height: 18px;
  stroke: #675dda;
  fill: none;
}
@media (hover: hover) {
  .filters__btn:hover {
    opacity: 0.85;
  }
}
.filters__btn:active {
  opacity: 0.75;
}

.filters-tooltip {
  transition: 0.25s linear;
  transition-property: opacity, visibility;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  z-index: 10;
  width: 460px;
  height: 420px;
  top: 100%;
  right: -100px;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
  background-color: #ffffff;
  padding: 16px;
  padding-right: 4px;
  border-radius: 8px;
}
.filters-tooltip__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  padding-right: 12px;
}
.filters-tooltip__header > span {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: 0.15px;
  color: #1c1b1f;
}
.filters-tooltip__body {
  height: calc(100% - 24px);
  padding-right: 8px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-color: #79747e transparent;
  scrollbar-width: thin;
}
.filters-tooltip__body::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: transparent;
}
.filters-tooltip__body::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}
.filters-tooltip__body::-webkit-scrollbar-thumb:vertical {
  background-color: #79747e;
  border-radius: 10px;
}
.filters-tooltip__body::-webkit-scrollbar-thumb:horizontal {
  background-color: #79747e;
  border-radius: 10px;
}
.filters-tooltip__body::-webkit-scrollbar-track, .filters-tooltip__body::-webkit-scrollbar-thumb {
  border-radius: 10px;
}
.filters-tooltip__section {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding-bottom: 16px;
}
.filters-tooltip__section:not(:last-child) {
  margin-bottom: 16px;
}
.filters-tooltip__section:not(:last-child)::before {
  display: block;
}
.filters-tooltip__section::before {
  display: none;
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #e8eaee;
  height: 1px;
  border-radius: 20px;
}
.filters-tooltip__section-name {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.1px;
  color: #79747e;
}
.filters-tooltip__wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 8px 4px;
}
.filters-tooltip__calendars {
  height: 48px;
  display: flex;
  column-gap: 12px;
}
.filters-tooltip__calendars .input-custom {
  flex-grow: 1;
}
.filters-tooltip__calendars .vertical-line {
  width: 1px;
  height: 100%;
  background-color: #e8eaee;
}
.filters-tooltip__btns {
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.filters-tooltip__close {
  padding: 0;
  background: none;
  border: none;
  width: 24px;
  height: 24px;
}
.filters-tooltip__close svg {
  pointer-events: none;
  width: 100%;
  height: 100%;
  stroke: #79747e;
}

@media (max-width: 767px) {
  .filters {
    position: static;
  }
  .filters-tooltip {
    border-radius: 0;
    right: auto;
    width: 100%;
    height: calc(100vh - 60px);
    top: 60px;
    left: 0;
  }
  .filters-tooltip__section {
    row-gap: 16px;
  }
  .filters-tooltip__close {
    display: block;
  }
}
.form__wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.form__wrapper-btns {
  display: flex;
  column-gap: 20px;
}
.form__wrapper-btns button {
  max-width: 325px;
  width: 100%;
}

@media (max-width: 575px) {
  .form__wrapper-btns {
    flex-direction: column;
    row-gap: 20px;
  }
}
.general-info {
  position: sticky;
  top: 116px;
  flex-shrink: 0;
  width: 325px;
  padding: 32px;
  background-color: #ffffff;
  border-radius: 20px;
}
.general-info__name {
  display: block;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
}
.general-info__tab {
  margin: 12px 0;
  background-color: #6750a4;
  border-radius: 10px;
  padding: 8px 16px;
  display: flex;
  align-items: start;
  justify-content: space-around;
  column-gap: 16px;
}
.general-info__tab-title, .general-info__weight-title {
  display: block;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  padding-bottom: 2px;
  letter-spacing: 0.4px;
  color: #d0bcff;
}
.general-info__weight-title {
  color: #49454f;
}
.general-info__tab-value, .general-info__weight-value {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #ffffff;
}
.general-info__weight-value {
  color: #1c1b1f;
}
.general-info__tab-decor {
  display: block;
  height: 30px;
  width: 1px;
  background-color: #7f67be;
}
.general-info__weight {
  border: 1px solid #e8eaee;
  border-radius: 10px;
  position: relative;
  padding: 8px 16px;
}
.general-info__weight-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}
.general-info__weight-el {
  line-height: 24px;
}
.general-info__weight-decor {
  width: 100%;
  margin: 8px 0;
  height: 1px;
  background-color: #e8eaee;
}
.general-info__decor {
  display: block;
  width: 100%;
  background-color: #e8eaee;
  height: 2px;
  margin: 24px 0;
}
.general-info__contacts {
  margin: 24px 0;
}
.general-info__contacts-el {
  display: flex;
  align-items: start;
  column-gap: 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #1c1b1f;
}
.general-info__contacts-el svg {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  fill: none;
  stroke: #7a7289;
}
.general-info__contacts-title {
  display: block;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  margin-bottom: 12px;
}
.general-info__contacts-content {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
.general-info__note {
  padding: 8px 16px;
  background-color: #6750a4;
  border-radius: 10px;
}
.general-info__note-title {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #d0bcff;
}
.general-info__note-content {
  color: #ffffff;
  letter-spacing: 0.5px;
}
.general-info__actions {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.remove-card {
  color: #dc362e;
}
.remove-card svg {
  stroke: #dc362e;
}

@media (max-width: 1199px) {
  .general-info {
    position: initial;
    width: 100%;
    display: grid;
    gap: 12px;
    grid-template-columns: repeat(3, 1fr);
  }
  .general-info__name {
    align-self: center;
  }
  .general-info__decor {
    grid-column: 1/4;
  }
  .general-info__tab {
    margin: 0;
  }
  .general-info__contacts {
    grid-column: 1/3;
    background-color: #f4f3ff;
    padding: 8px 16px;
    border-radius: 10px;
  }
  .general-info__note {
    grid-row: 4;
    grid-column: 1/4;
  }
  .general-info__actions {
    margin-top: 0;
  }
}
@media (max-width: 991px) {
  .general-info {
    display: block;
  }
  .general-info__tab {
    margin: 12px 0;
    justify-content: space-around;
  }
  .general-info__actions {
    margin-top: 24px;
  }
}
.group {
  width: 100%;
  opacity: 0;
  background-color: #ffffff;
  border-radius: 20px;
}
.group.is-filled > .group__header .group__completed {
  display: block;
}
.group.is-filled > .group__header .group__subtitle {
  padding-left: 40px;
}
.group.is-active .group__header {
  border-color: #e8eaee;
}
.group.is-active .group__inner-wrapper {
  overflow-x: visible;
  overflow-y: initial;
}
.group.is-active .group__subtitle {
  display: block;
}
.group.is-active .group__inner {
  opacity: 1;
}
.group.is-active .group__toggle svg {
  transform: rotate(0);
}
.group__header {
  cursor: pointer;
  padding: 24px 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid transparent;
  transition: 0.25s linear;
  transition-property: border, height;
}
.group__title {
  display: flex;
  align-items: center;
  column-gap: 8px;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
}
.group__subtitle {
  display: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #49454f;
}
.group__title-subgroup {
  color: #675dda;
  font-size: 22px;
  font-weight: 500;
  line-height: 28px;
  display: flex;
  align-items: flex-end;
  column-gap: 16px;
}
.group__title-subgroup span {
  white-space: nowrap;
}
.group__title-subgroup::after {
  bottom: 6px;
  position: relative;
  content: "";
  display: inline-block;
  width: 100%;
  height: 2px;
  background-color: #e8eaee;
}
.group__completed {
  display: none;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
}
.group__completed svg {
  stroke: #52b530;
}
.group__buttons {
  display: flex;
  align-items: center;
  gap: 16px;
}
.group__toggle {
  cursor: pointer;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
}
.group__toggle svg {
  transition: 0.25s linear;
  transition-property: transform;
  stroke: #79747e;
  pointer-events: none;
  transform: rotate(180deg);
}
.group__inner-wrapper {
  position: relative;
  height: 0;
  overflow-y: hidden;
  transition: 0.25s linear;
  transition-property: height, overflow;
}
.group__inner {
  transition: 0.25s linear;
  transition-property: opacity;
  opacity: 0;
}
.group__form, .group__additional-form {
  padding: 44px 32px;
}
.group--additional {
  border-radius: 0 0 20px 20px;
  opacity: 1;
  border-top: 2px solid #e8eaee;
  display: none;
}
.group--additional .group__header {
  cursor: auto;
  padding-top: 44px;
  padding-bottom: 0;
  border: none;
}
.group--additional.is-active {
  display: block;
}
.group--additional[data-connected] {
  display: none;
}
.group--additional[data-connected].is-active {
  display: block;
}
.group--simple {
  background-color: #f4f3ff;
  border-radius: 0;
}
.group--simple .group__header {
  padding: 32px 40px;
  border-bottom: none;
}
.group--simple .group__form {
  padding: 0 40px 32px;
}
.group--simple .group__form:empty {
  padding: 0;
}
.group--additional.group--simple {
  position: relative;
  border: none;
}
.group--additional.group--simple:before {
  content: "";
  width: calc(100% - 80px);
  background-color: #e8eaee;
  height: 2px;
  position: absolute;
  top: 0;
  left: 40px;
}
.group--additional.group--simple .group__additional-form {
  padding: 0 40px 32px;
}
.group__info {
  padding: 44px 32px;
  color: #675dda;
  font-weight: 500;
  letter-spacing: 0.15px;
}
.group__add-info {
  display: inline-block;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #79747e;
  align-self: end;
  margin-left: 8px;
}

@media (max-width: 767px) {
  .group--simple .group__header {
    padding: 32px 20px;
  }
  .group--simple .group__form {
    padding: 0 20px 32px;
  }
  .group--additional.group--simple .group__additional-form {
    padding: 0 20px 32px;
  }
  .group--additional.group--simple:before {
    width: calc(100% - 40px);
    left: 20px;
  }
  .group__header {
    padding: 24px 20px;
    column-gap: 8px;
  }
  .group__form, .group__additional-form {
    padding: 44px 20px;
  }
  .group__title {
    align-items: flex-start;
    font-size: 20px;
  }
}
@media (max-width: 575px) {
  .group__title-subgroup {
    display: block;
  }
  .group__title-subgroup::after {
    display: none;
  }
}
.group-radio-buttons[data-connected] {
  display: none;
}
.group-radio-buttons[data-required].hightlight .group-radio-buttons__title {
  animation: titleLight 3s ease 0s infinite;
}
.group-radio-buttons.is-filled.hightlight .group-radio-buttons__title {
  animation: none;
}
.group-radio-buttons.is-invalid.hightlight .group-radio-buttons__title {
  animation: titleErrorLight 3s ease 0s infinite;
}
.group-radio-buttons.is-active {
  display: block;
}
.group-radio-buttons__title {
  display: flex;
  column-gap: 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin-bottom: 8px;
  color: #49454f;
}
.group-radio-buttons__options {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}
.group-radio-buttons__options label {
  white-space: nowrap;
}

@keyframes titleLight {
  0% {
    color: #49454f;
  }
  50% {
    color: #675dda;
  }
  100% {
    color: #49454f;
  }
}
@keyframes titleErrorLight {
  0% {
    color: #b3261e;
  }
  50% {
    color: #b3261e;
  }
  100% {
    color: #b3261e;
  }
}
.info {
  width: 24px;
  height: 24px;
  background-color: transparent;
  cursor: pointer;
}
.info svg {
  width: 100%;
  height: 100%;
  fill: none;
  stroke: #49454f;
  pointer-events: none;
}

.input-custom {
  position: relative;
}
.input-custom__input {
  width: 100%;
  position: relative;
}
.input-custom__input:has(.calendar-toggler) input {
  padding-right: 50px;
}
.input-custom__input:has(.calendar-toggler) label {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 60px);
}
.input-custom__input:has(.button-eye) input {
  padding-right: 48px;
}
.input-custom[data-connected] {
  display: none;
}
.input-custom[data-connected].is-active {
  display: block;
}
.input-custom:has(input[data-connected]) {
  display: none;
}
.input-custom:has(input[data-connected].is-active) {
  display: block;
}
.input-custom:has(input[data-required].hightlight) input {
  animation: shadowViolet 3s linear 0s infinite;
  border-color: #675dda;
}
.input-custom input {
  display: block;
  width: 100%;
  height: 56px;
  padding: 16px;
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #49454f;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid #49454f;
  border-radius: 4px;
  appearance: none;
  transition: 0.25s linear;
  transition-property: border-color, box-shadow;
}
.input-custom input.input-custom--violet {
  background-color: #f4f3ff;
}
.input-custom input.input-custom--violet ~ label {
  background-color: #f4f3ff;
}
.input-custom input[type=search]::-webkit-search-decoration,
.input-custom input[type=search]::-webkit-search-cancel-button,
.input-custom input[type=search]::-webkit-search-results-button,
.input-custom input[type=search]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}
.input-custom input::placeholder {
  color: transparent;
}
.input-custom input::placeholder::-moz-placeholder {
  color: transparent;
}
.input-custom input:hover,
.input-custom input:not(:placeholder-shown):not(.is-invalid):hover {
  border-color: #675dda;
}
.input-custom input:focus {
  border-color: #675dda !important;
  animation: none !important;
  box-shadow: 0px 0px 0px 1px #675dda;
}
.input-custom input:focus ~ label {
  color: #675dda;
}
.input-custom input:not(:placeholder-shown):not(.is-invalid):focus ~ label {
  color: #675dda;
}
.input-custom input:focus ~ label,
.input-custom input:not(:placeholder-shown) ~ label {
  top: 0;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
}
.input-custom input:not(:placeholder-shown) {
  color: #1c1b1f;
}
.input-custom input:not(:placeholder-shown):not(.is-invalid).hightlight {
  border-color: #49454f;
  animation: none;
}
.input-custom input:not(:placeholder-shown) ~ label {
  color: #49454f;
}
.input-custom:has(input:disabled) {
  pointer-events: none;
  opacity: 0.5;
}
.input-custom:has(input.is-invalid) input {
  border-color: #b3261e !important;
}
.input-custom:has(input.is-invalid) input ~ label {
  color: #b3261e;
}
.input-custom:has(input.is-invalid) .input-custom__message {
  display: block;
}
.input-custom:has(input[data-required].is-invalid.hightlight) input {
  animation: shadowRed 3s linear 0s infinite;
}
.input-custom input.is-invalid:focus {
  box-shadow: 0px 0px 0px 1px #b3261e;
}
.input-custom input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px #ffffff inset;
  -webkit-text-fill-color: #49454f;
}
.input-custom label {
  max-width: calc(100% - 32px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
  pointer-events: none;
  border: none;
  background-color: #ffffff;
  color: #49454f;
  padding: 0 4px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  transition: 0.2s linear;
  transition-property: top, font-size;
}
.input-custom__message {
  display: none;
  position: absolute;
  top: calc(100% + 4px);
  left: 16px;
  color: #49454f;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
}
.input-custom__message.error-message {
  color: #b3261e;
}
.input-custom.have-message .input-custom__message {
  display: block;
}
.input-custom__info {
  position: absolute;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  width: 24px;
  height: 24px;
  fill: none;
  stroke: #49454f;
}
.input-custom--support-txt .input-custom__message {
  display: block;
}
.input-custom--info input {
  padding-right: 40px;
}
.input-custom--ghost input {
  height: auto;
  border: none;
  padding: 12px 0;
}
.input-custom--ghost input:focus {
  box-shadow: none;
}
.input-custom--ghost input:focus ~ label,
.input-custom--ghost input:not(:placeholder-shown) ~ label {
  opacity: 0;
  top: 50%;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: normal;
}
.input-custom--ghost label {
  left: 0;
}
.input-custom--ghost .calendar-toggler {
  width: 24px;
  height: 24px;
  right: 0;
}
.input-custom--ghost .calendar-toggler svg {
  transition: 0.25s linear;
  transition-property: stroke;
  stroke: #49454f;
}
@media (hover: hover) {
  .input-custom--ghost .calendar-toggler:hover {
    background-color: transparent;
  }
  .input-custom--ghost .calendar-toggler:hover svg {
    stroke: #675dda;
  }
}
.input-custom .button-eye {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  width: 24px;
  height: 24px;
  justify-content: center;
}
.input-custom .button-eye svg {
  width: 100%;
  height: 100%;
  stroke: #49454f;
}

.itc-select {
  position: relative;
  height: 56px;
  width: 100%;
}

.itc-select.itc-select--violet .itc-select__toggle,
.itc-select.itc-select--violet .itc-select__placeholder {
  background-color: #f4f3ff;
}

.itc-select input {
  z-index: -1;
  width: 0.5px;
  height: 0.5px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.itc-select[data-required].hightlight .itc-select__toggle {
  border-color: #675dda;
  animation: shadowViolet 3s linear 0s infinite;
}

.itc-select[data-connected] {
  display: none;
}
.itc-select[data-connected].is-active {
  display: block;
}

.itc-select.have-message .itc-select__message {
  display: block;
}

.itc-select__message {
  position: absolute;
  display: none;
  margin-top: 4px;
  padding-left: 16px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
}

.itc-select.is-invalid .itc-select__toggle {
  border-color: #b3261e;
}
.itc-select.is-invalid .itc-select__message {
  display: block;
  color: #b3261e;
}
.itc-select.is-invalid .itc-select__placeholder {
  color: #b3261e;
}

.itc-select.is-invalid.hightlight .itc-select__toggle {
  border-color: #b3261e;
  animation: shadowRed 3s linear 0s infinite;
}

.itc-select.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.itc-select:has(.itc-select__text-selected:not(:empty)) .itc-select__toggle {
  border-color: #79747e;
  animation: none;
}
.itc-select:has(.itc-select__text-selected:not(:empty)) .itc-select__toggle:hover {
  border-color: #675dda;
}

.itc-select__toggle {
  display: grid;
  justify-content: space-between;
  align-items: center;
  grid-template-columns: auto auto;
  column-gap: 24px;
  width: 100%;
  padding: 15px 16px;
  font-size: 16px;
  line-height: 22px;
  white-space: nowrap;
  color: #49454f;
  background-color: #ffffff;
  border: 1px solid #79747e;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;
}

.itc-select__text-selected {
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  column-gap: 8px;
}

.itc-select__additional {
  display: none;
  width: 16px;
  height: 16px;
  background-color: #675dda;
  border-radius: 50%;
  text-align: center;
  color: #ffffff;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.5px;
}

.itc-select:has(.itc-select__option_selected) .itc-select__additional {
  display: block;
}

.itc-select__toggle:hover {
  border-color: #675dda;
}

.itc-select__toggle svg {
  transition: 0.25s linear;
  transition-property: transform;
  grid-column-start: 2;
  display: block;
  justify-self: end;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  transform: rotate(180deg);
  fill: none;
  stroke: #79747e;
  content: "";
}

.itc-select__toggle:focus {
  outline: none;
}

.itc-select_show .itc-select__toggle {
  border-color: #675dda;
}
.itc-select_show .itc-select__toggle svg {
  transform: rotate(0);
  stroke: #675dda;
}
.itc-select_show .itc-select__placeholder {
  width: auto;
  color: #675dda;
  top: 0;
  font-size: 12px;
  line-height: 16px;
}

.itc-select__placeholder {
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
  pointer-events: none;
  border: none;
  background-color: #ffffff;
  color: #49454f;
  padding: 0 4px;
  font-size: 16px;
  letter-spacing: 0.4px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  transition: 0.2s linear;
  transition-property: top, font-size, color;
  max-width: calc(100% - 66px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.itc-select__dropdown {
  transition: 0.25s linear;
  transition-property: visibility, opacity;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  z-index: 2;
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
  background: linear-gradient(0deg, rgba(103, 80, 164, 0.08) 0%, rgba(103, 80, 164, 0.08) 100%), #fffbfe;
  border: 1px solid #ccc;
  padding: 8px 5px 8px 0;
  border-radius: 4px;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
}

.itc-select_show .itc-select__dropdown {
  visibility: visible;
  pointer-events: initial;
  opacity: 1;
}

.itc-select_show .itc-select__backdrop {
  display: block;
}

.itc-select__options {
  margin: 0;
  padding: 0;
  max-height: 240px;
  overflow-y: auto;
  list-style: none;
  -webkit-overflow-scrolling: touch;
  scrollbar-color: #79747e transparent;
  scrollbar-width: thin;
}
.itc-select__options::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: transparent;
}
.itc-select__options::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}
.itc-select__options::-webkit-scrollbar-thumb:vertical {
  background-color: #79747e;
  border-radius: 10px;
}
.itc-select__options::-webkit-scrollbar-thumb:horizontal {
  background-color: #79747e;
  border-radius: 10px;
}
.itc-select__options::-webkit-scrollbar-track, .itc-select__options::-webkit-scrollbar-thumb {
  border-radius: 10px;
}

.itc-select__option {
  transition: 0.25s linear;
  transition-property: color;
  padding: 12px 24px 12px 16px;
}

.itc-select__option.disabled {
  opacity: 0.4;
  pointer-events: none;
}

.itc-select__option_selected {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #675dda;
}

.itc-select:has(.itc-select__option_selected) .itc-select__placeholder {
  width: auto;
  top: 0;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
}

.itc-select__option_selected::after {
  width: 0.75rem;
  height: 0.75rem;
  color: #0277bd;
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" height="100" width="100" class="svg-inline--fa fa-check fa-w-16" data-icon="check" data-prefix="fas" aria-hidden="true"%3E%3Cpath d="M33.964 85.547l-32.5-32.251a4.935 4.935 0 010-7.017l7.071-7.017a5.027 5.027 0 017.071 0L37.5 60.987l46.894-46.534a5.028 5.028 0 017.07 0l7.072 7.017a4.935 4.935 0 010 7.017l-57.5 57.06a5.027 5.027 0 01-7.072 0z" fill="%230277bd"/%3E%3C/svg%3E');
  background-size: cover;
  content: "";
}

.itc-select__option:hover {
  color: #675dda;
  cursor: pointer;
}

.itc-select--classic {
  height: auto;
  min-width: 70px;
}
.itc-select--classic .itc-select__toggle {
  border: none;
  background-color: transparent;
  column-gap: 8px;
  padding: 0 8px;
}
.itc-select--classic .itc-select__toggle svg {
  width: 7.5px;
  height: 3.5px;
  stroke: #49454f;
  transform: rotate(0);
}
.itc-select--classic .itc-select__placeholder {
  display: none;
}
.itc-select--classic.itc-select_show .itc-select__toggle svg {
  transform: rotate(180deg);
}
.itc-select--classic .itc-select__option {
  padding: 5px;
}
.itc-select--classic .itc-select__text-selected {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
}

@media (max-width: 575px) {
  .itc-select--classic {
    min-width: 40px;
  }
}
.logo-full {
  display: block;
  width: 440px;
  text-align: center;
}
.logo-full p {
  color: #675dda;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
}

.i-modal {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  overflow: auto;
  transition: 0.3s ease-in-out;
  transition-property: opacity, visibility, z-index;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  -webkit-overflow-scrolling: touch;
  padding: 34px;
}
.i-modal.is-active {
  opacity: 1;
  visibility: visible;
  z-index: 1001;
}
.i-modal.is-active .i-modal__content {
  opacity: 1;
  transform: translateY(0) scale(1);
}
.i-modal.i-modal--2deep {
  z-index: 1002;
  transition-property: opacity, visibility;
}
.i-modal.i-modal--3deep {
  z-index: 1003;
  transition-property: opacity, visibility;
}
.i-modal__close {
  width: 36px;
  height: 36px;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  z-index: 2;
  transition: 0.25s linear;
  transition-property: background;
  color: slategray;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.i-modal__close svg {
  transition: 0.25s linear;
  transition-property: fill, stroke, color;
}
.i-modal__content {
  max-width: 744px;
  width: 100%;
  margin: auto;
  border-radius: 20px;
  background-color: #fff;
  position: relative;
  padding: 44px 32px;
  opacity: 0;
  transform: translateY(-20%) scale(0.8);
  transition: all 0.3s ease-in-out;
}
.i-modal__title {
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  position: relative;
  text-align: center;
  margin-bottom: 24px;
}
.i-modal__subtitle {
  text-align: center;
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 24px;
}

@media (max-width: 767px) {
  .i-modal {
    padding: 20px;
  }
  .i-modal__content {
    padding: 30px 20px;
  }
}
.modal-style .i-modal__content {
  max-width: 670px;
}
.modal-style__title {
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  color: #000000;
  text-align: center;
}
.modal-style__subtitle {
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #79747e;
  margin-bottom: 24px;
}
.modal-style__subtitle br {
  display: none;
}
.modal-style__buttons {
  margin-top: 44px;
  display: flex;
  justify-content: space-between;
  column-gap: 20px;
}
.modal-style__buttons .btn {
  width: auto;
  flex-grow: 1;
}

@media (max-width: 767px) {
  .modal-style__subtitle br {
    display: block;
  }
}
.modal-result-query .i-modal__inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 16px;
}
.modal-result-query.rejected img {
  display: none;
}
.modal-result-query__title {
  margin: 0;
}
.modal-result-query img {
  display: block;
  width: 130px;
  height: 130px;
}

.modal-transfer-patient__title {
  margin-bottom: 24px;
}

.modal-upload__files {
  margin-bottom: 24px;
}
.modal-upload__btns {
  display: flex;
  column-gap: 20px;
  margin-top: 44px;
}
.modal-upload__btns .btn {
  flex-grow: 1;
}

.page-section {
  padding: 32px;
  background-color: #ffffff;
  border-radius: 20px;
}
.page-section.not-empty .placeholder {
  display: none;
}
.page-section--additional {
  padding: 0;
  background-color: transparent;
  border: initial;
}
.page-section__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 44px;
  margin-bottom: 24px;
}
.page-section__title {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  color: #000000;
}
.page-section__actions {
  display: flex;
  column-gap: 16px;
}
.page-section__message {
  display: none;
  margin-top: 24px;
  font-weight: 500;
  color: #52b530;
}
.page-section__message.is-active {
  display: block;
}
.page-section__message.error {
  color: #b3261e;
}

@media (max-width: 767px) {
  .page-section {
    padding: 20px;
  }
  .page-section--additional {
    padding: 0;
  }
  .page-section__header {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 24px;
  }
  .page-section__actions {
    flex-direction: column;
    row-gap: 16px;
  }
}
.pill {
  position: relative;
  border: 1px solid #49454f;
  border-radius: 8px;
  height: 28px;
  padding: 0 8px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 8px;
  cursor: pointer;
  transition: 0.25s linear;
  transition-property: border-color;
}
.pill.is-active {
  border-color: #675dda;
}
.pill.is-active .pill-inner {
  opacity: 1;
  visibility: visible;
}
.pill.is-active .pill__arrow {
  transform: rotate(0);
}
.pill--operation .pill-inner__content-wrap {
  display: grid;
  grid-template-columns: repeat(4, 79px);
  gap: 8px 4px;
}
.pill--complication .pill-inner__content-wrap {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
@media (hover: hover) {
  .pill:hover {
    border-color: #675dda;
  }
}
.pill__name {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #49454f;
}
.pill__count {
  display: inline-block;
  padding: 0 4px;
  min-width: 16px;
  height: 16px;
  background-color: #675dda;
  border-radius: 50%;
  text-align: center;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: #ffffff;
  letter-spacing: 0.5px;
}
.pill svg {
  fill: none;
  stroke: #49454f;
  width: 18px;
  height: 18px;
}
.pill__arrow {
  transition: 0.25s linear;
  transition-property: transform;
  transform: rotate(180deg);
}
.pill__complication, .pill__observation {
  cursor: pointer;
  border-radius: 8px;
  background-color: #f4f3ff;
  text-align: center;
  padding: 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #49454f;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.pill__complication {
  padding: 4px 15.5px;
}
.pill__observation--transparent {
  transition: 0.25s linear;
  transition-property: border-color;
  flex-shrink: 0;
  max-width: 100px;
  width: 100%;
  background-color: transparent;
  border: 1px solid #79747e;
  border-radius: 8px;
  outline: none;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
@media (hover: hover) {
  .pill__observation--transparent:hover {
    border-color: #675dda;
  }
}
.pill__complication {
  display: flex;
  column-gap: 8px;
  justify-content: space-between;
}
.pill__complication-name {
  overflow: hidden;
  text-overflow: ellipsis;
}

.pill-inner {
  transition: 0.25s linear;
  transition-property: opacity, visibility;
  opacity: 0;
  visibility: hidden;
  cursor: auto;
  width: 360px;
  position: absolute;
  z-index: 2;
  top: calc(100% + 4px);
  left: 0;
  background-color: #ffffff;
  padding: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  border: 1px solid #49454f;
  border-radius: 8px;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
}
.pill-inner__content-name {
  display: block;
  margin-bottom: 8px;
}
.pill-inner__link {
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #675dda;
}
.pill-inner__link svg {
  transform: rotate(90deg);
  stroke: #675dda;
}

.pill-inner__content-name {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #49454f;
}

@media (max-width: 767px) {
  .pill--operation .pill-inner__content-wrap {
    grid-template-columns: repeat(2, 79px);
  }
  .pill__observation {
    padding: 4px;
  }
  .pill-inner {
    width: 250px;
  }
  .pill-inner__content-wrap {
    max-height: 136px;
    overflow: auto;
    overflow-x: hidden;
    padding-right: 4px;
    -webkit-overflow-scrolling: touch;
    scrollbar-color: #79747e transparent;
    scrollbar-width: thin;
  }
  .pill-inner__content-wrap::-webkit-scrollbar {
    width: 2px;
    height: 2px;
    background-color: transparent;
  }
  .pill-inner__content-wrap::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 10px;
  }
  .pill-inner__content-wrap::-webkit-scrollbar-thumb:vertical {
    background-color: #79747e;
    border-radius: 10px;
  }
  .pill-inner__content-wrap::-webkit-scrollbar-thumb:horizontal {
    background-color: #79747e;
    border-radius: 10px;
  }
  .pill-inner__content-wrap::-webkit-scrollbar-track, .pill-inner__content-wrap::-webkit-scrollbar-thumb {
    border-radius: 10px;
  }
  .pill-inner__content-wrap::-webkit-scrollbar-track {
    margin-left: 10px;
  }
}
@media (max-width: 575px) {
  .pill-inner {
    width: 200px;
  }
}
.placeholder {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}
.placeholder__title, .placeholder__text {
  display: block;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #6750a4;
}
.placeholder__text {
  color: #79747e;
}

.radio {
  display: flex;
  column-gap: 8px;
  align-items: flex-start;
}
.radio.disabled {
  pointer-events: none;
  opacity: 0.4;
}
.radio input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.radio .radio__fake {
  position: relative;
  padding: 3px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  user-select: none;
}
.radio .radio__fake::before {
  transition: 0.25s linear;
  transition-property: border-color;
  content: "";
  display: inline-block;
  width: 18px;
  height: 18px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 2px solid #515151;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 90% 90%;
}
.radio .radio__fake::after {
  content: "";
  display: none;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  height: 40%;
  border-radius: 50%;
}
.radio input:checked + .radio__fake::before {
  border-color: #675dda;
}
.radio input:checked + .radio__fake::after {
  display: block;
  background-color: #675dda;
}
.radio input:not(:disabled):not(:checked) + .radio__fake:hover::before {
  border-color: #675dda;
}
.radio input:disabled + .radio__fake::before {
  pointer-events: none;
  opacity: 0.4;
}

.radio__label {
  font-weight: 400;
  line-height: 24px;
  font-size: 16px;
  color: #49454f;
  cursor: pointer;
}

.search {
  position: relative;
  display: block;
  height: 20px;
}
.search.is-active .search__input {
  pointer-events: initial;
  opacity: 1;
}
.search__input {
  transition: 0.25s linear;
  transition-property: opacity, visibility;
  width: 411px;
  position: absolute;
  z-index: 10;
  left: -143px;
  top: -20px;
  height: 56px;
  pointer-events: none;
  opacity: 0;
}
.search__input input {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(103, 80, 164, 0.08) 0%, rgba(103, 80, 164, 0.08) 100%), #fffbfe;
  border-radius: 4px;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
  border: none;
  outline: none;
  padding: 4px 52px 4px 16px;
  caret-color: #675dda;
}
.search__input input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px #f4f3ff inset;
  -webkit-text-fill-color: #49454f;
}
.search__clear {
  cursor: pointer;
  position: absolute;
  top: 4px;
  right: 4px;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.search__clear svg {
  width: 50%;
  height: 50%;
  stroke: #49454f;
}

@media (max-width: 767px) {
  .search__input {
    left: 0;
  }
}
@media (max-width: 575px) {
  .search__input {
    left: 0;
    width: 350px;
  }
}
.side-modal.i-modal {
  overflow: hidden;
  z-index: 50;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  padding: 0;
  opacity: 1;
  transition-property: transform;
  transform: translateX(100%);
  background-color: transparent;
}
.side-modal.i-modal.is-active {
  opacity: 1;
  visibility: visible;
  z-index: 50;
  transform: translateX(0);
}
.side-modal.i-modal.is-active .i-modal__content {
  opacity: 1;
}
.side-modal.i-modal.view-mode .side-modal__bottom {
  display: none;
}
.side-modal.i-modal.view-mode .i-modal__inner {
  padding-bottom: 0;
}
.side-modal.i-modal .i-modal__content {
  max-width: 940px;
  opacity: 1;
  margin: 0;
  margin-left: auto;
  padding: 0;
  padding-top: 96px;
  width: 100%;
  height: 100%;
  border-radius: 0;
  transform: none;
}
.side-modal.i-modal .i-modal__inner {
  position: relative;
  height: 100%;
}
.side-modal.i-modal form {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.side-modal.i-modal .side-modal__header {
  position: sticky;
  z-index: 2;
  top: 0;
  background-color: #ffffff;
  left: 0;
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 24px;
  padding-bottom: 24px;
}
.side-modal.i-modal .side-modal__header::before {
  content: "";
  display: block;
  width: calc(100% - 80px);
  height: 2px;
  position: absolute;
  background-color: #e8eaee;
  bottom: 0;
  left: 40px;
}
.side-modal.i-modal .side-modal__header.is-scrolled {
  margin-bottom: 0;
}
.side-modal.i-modal .side-modal__header.is-scrolled::before {
  display: none;
}
.side-modal.i-modal .side-modal__add-txt {
  display: none;
  align-items: center;
  column-gap: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  margin-bottom: 8px;
  color: #49454f;
  cursor: pointer;
}
.side-modal.i-modal .side-modal__add-txt svg {
  width: 18px;
  height: 18px;
  stroke: #49454f;
  fill: none;
  transform: rotate(90deg);
}
.side-modal.i-modal .side-modal__header-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.side-modal.i-modal .side-modal__header-buttons {
  display: flex;
  align-items: center;
  column-gap: 16px;
}
.side-modal.i-modal .side-modal__header-buttons .btn {
  display: none;
}
.side-modal.i-modal .btn.side-modal__close {
  display: inline-flex;
}
.side-modal.i-modal .side-modal__remove svg {
  stroke: #dc362e;
}
.side-modal.i-modal .side-modal__title {
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  color: #000000;
}
.side-modal.i-modal .side-modal__main {
  position: relative;
  padding-top: 24px;
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  overflow: auto;
  height: calc(100% - 174px);
}
.side-modal.i-modal .side-modal__grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 44px 24px;
}
.side-modal.i-modal .side-modal__bottom {
  position: absolute;
  left: 0;
  z-index: 2;
  bottom: 0;
  padding: 24px 40px;
  background-color: #ffffff;
  border-top: 2px solid #e8eaee;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 20px;
}
.side-modal.i-modal .side-modal__bottom .btn {
  max-width: 240px;
  width: 100%;
}
.side-modal.i-modal .long {
  grid-column-end: span 2;
}

.side-modal[data-side-modal-name=operation] .file-loader,
.side-modal[data-side-modal-name=observation] .file-loader,
.side-modal[data-side-modal-name=patient] .file-loader {
  padding: 0 40px;
}
.side-modal[data-side-modal-name=operation] .side-modal__main,
.side-modal[data-side-modal-name=observation] .side-modal__main,
.side-modal[data-side-modal-name=patient] .side-modal__main {
  height: 100%;
}
.side-modal[data-side-modal-name=operation].is-editable .group__form,
.side-modal[data-side-modal-name=operation].is-editable .group__additional-form,
.side-modal[data-side-modal-name=observation].is-editable .group__form,
.side-modal[data-side-modal-name=observation].is-editable .group__additional-form,
.side-modal[data-side-modal-name=patient].is-editable .group__form,
.side-modal[data-side-modal-name=patient].is-editable .group__additional-form {
  gap: 44px 24px;
}
.side-modal[data-side-modal-name=operation].is-editable .side-modal__main,
.side-modal[data-side-modal-name=observation].is-editable .side-modal__main,
.side-modal[data-side-modal-name=patient].is-editable .side-modal__main {
  height: calc(100% - 174px);
}
.side-modal[data-side-modal-name=operation].is-editable .group__header,
.side-modal[data-side-modal-name=observation].is-editable .group__header,
.side-modal[data-side-modal-name=patient].is-editable .group__header {
  padding-bottom: 44px;
}
.side-modal[data-side-modal-name=operation].is-editable .side-modal__show-required,
.side-modal[data-side-modal-name=observation].is-editable .side-modal__show-required,
.side-modal[data-side-modal-name=patient].is-editable .side-modal__show-required {
  display: inline-flex;
}
.side-modal[data-side-modal-name=operation].is-editable .group__title-subgroup,
.side-modal[data-side-modal-name=observation].is-editable .group__title-subgroup,
.side-modal[data-side-modal-name=patient].is-editable .group__title-subgroup {
  color: #675dda;
}
.side-modal[data-side-modal-name=operation] .side-modal__main,
.side-modal[data-side-modal-name=observation] .side-modal__main,
.side-modal[data-side-modal-name=patient] .side-modal__main {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 0;
  padding-top: 24px;
}
.side-modal[data-side-modal-name=operation] .group__title-subgroup,
.side-modal[data-side-modal-name=observation] .group__title-subgroup,
.side-modal[data-side-modal-name=patient] .group__title-subgroup {
  color: #000000;
  grid-column-start: 1;
  grid-column-end: 3;
}
.side-modal[data-side-modal-name=operation] .group__title-subgroup:last-child,
.side-modal[data-side-modal-name=observation] .group__title-subgroup:last-child,
.side-modal[data-side-modal-name=patient] .group__title-subgroup:last-child {
  display: none;
}
.side-modal[data-side-modal-name=operation] .group__form,
.side-modal[data-side-modal-name=operation] .group__additional-form,
.side-modal[data-side-modal-name=observation] .group__form,
.side-modal[data-side-modal-name=observation] .group__additional-form,
.side-modal[data-side-modal-name=patient] .group__form,
.side-modal[data-side-modal-name=patient] .group__additional-form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
}
.side-modal[data-side-modal-name=operation] .group__form .data-block:only-child,
.side-modal[data-side-modal-name=operation] .group__additional-form .data-block:only-child,
.side-modal[data-side-modal-name=observation] .group__form .data-block:only-child,
.side-modal[data-side-modal-name=observation] .group__additional-form .data-block:only-child,
.side-modal[data-side-modal-name=patient] .group__form .data-block:only-child,
.side-modal[data-side-modal-name=patient] .group__additional-form .data-block:only-child {
  grid-column-end: span 2;
}
.side-modal[data-side-modal-name=operation] .group.three-columns .group__form,
.side-modal[data-side-modal-name=operation] .group.three-columns .group__additional-form,
.side-modal[data-side-modal-name=observation] .group.three-columns .group__form,
.side-modal[data-side-modal-name=observation] .group.three-columns .group__additional-form,
.side-modal[data-side-modal-name=patient] .group.three-columns .group__form,
.side-modal[data-side-modal-name=patient] .group.three-columns .group__additional-form {
  grid-template-columns: repeat(3, 1fr);
}
.side-modal[data-side-modal-name=operation] .group.three-columns .long,
.side-modal[data-side-modal-name=observation] .group.three-columns .long,
.side-modal[data-side-modal-name=patient] .group.three-columns .long {
  grid-column-end: span 3;
}
.side-modal[data-side-modal-name=operation] .side-modal__header-buttons .side-modal__edit,
.side-modal[data-side-modal-name=operation] .side-modal__header-buttons .side-modal__print,
.side-modal[data-side-modal-name=operation] .side-modal__header-buttons .side-modal__remove,
.side-modal[data-side-modal-name=observation] .side-modal__header-buttons .side-modal__edit,
.side-modal[data-side-modal-name=observation] .side-modal__header-buttons .side-modal__print,
.side-modal[data-side-modal-name=observation] .side-modal__header-buttons .side-modal__remove,
.side-modal[data-side-modal-name=patient] .side-modal__header-buttons .side-modal__edit,
.side-modal[data-side-modal-name=patient] .side-modal__header-buttons .side-modal__print,
.side-modal[data-side-modal-name=patient] .side-modal__header-buttons .side-modal__remove {
  display: inline-flex;
}
.side-modal[data-side-modal-name=operation] .input-custom input,
.side-modal[data-side-modal-name=operation] .input-custom input ~ label,
.side-modal[data-side-modal-name=observation] .input-custom input,
.side-modal[data-side-modal-name=observation] .input-custom input ~ label,
.side-modal[data-side-modal-name=patient] .input-custom input,
.side-modal[data-side-modal-name=patient] .input-custom input ~ label {
  background-color: #f4f3ff;
}
.side-modal[data-side-modal-name=operation] .itc-select .itc-select__toggle,
.side-modal[data-side-modal-name=operation] .itc-select .itc-select__placeholder,
.side-modal[data-side-modal-name=observation] .itc-select .itc-select__toggle,
.side-modal[data-side-modal-name=observation] .itc-select .itc-select__placeholder,
.side-modal[data-side-modal-name=patient] .itc-select .itc-select__toggle,
.side-modal[data-side-modal-name=patient] .itc-select .itc-select__placeholder {
  background-color: #f4f3ff;
}
.side-modal[data-side-modal-name=operation] .textarea textarea,
.side-modal[data-side-modal-name=operation] .textarea label,
.side-modal[data-side-modal-name=observation] .textarea textarea,
.side-modal[data-side-modal-name=observation] .textarea label,
.side-modal[data-side-modal-name=patient] .textarea textarea,
.side-modal[data-side-modal-name=patient] .textarea label {
  background-color: #f4f3ff;
}

.side-modal[data-side-modal-name=operation] .side-modal__header-buttons .side-modal__add-file {
  display: inline-flex;
}

.side-modal[data-side-modal-name=observation].is-editable .side-modal__main {
  height: calc(100% - 202px);
}

.side-modal[data-side-modal-name=observation] .side-modal__add-txt {
  display: flex;
}
.side-modal[data-side-modal-name=observation].is-editable .side-modal__header-buttons .side-modal__add-file {
  display: inline-flex;
}

.side-modal[data-side-modal-name=patient] .side-modal__header-buttons .side-modal__edit,
.side-modal[data-side-modal-name=patient] .side-modal__header-buttons .side-modal__print,
.side-modal[data-side-modal-name=patient] .side-modal__header-buttons .side-modal__remove {
  display: none;
}
.side-modal[data-side-modal-name=patient] .side-modal__header-buttons .side-modal__add-file {
  display: inline-flex;
}
.side-modal[data-side-modal-name=patient] .side-modal__header-buttons .side-modal__show-required {
  display: inline-flex;
}

.side-modal[data-side-modal-name=editing-assistant] .side-modal__header-buttons .side-modal__remove {
  display: inline-flex;
}

@media (max-width: 767px) {
  .side-modal.i-modal .i-modal__content {
    padding-top: 60px;
  }
  .side-modal.i-modal .side-modal__header {
    padding: 20px;
  }
  .side-modal.i-modal .side-modal__header::before {
    width: calc(100% - 40px);
    left: 20px;
  }
  .side-modal.i-modal .side-modal__main {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
  }
  .side-modal.i-modal .group__form,
  .side-modal.i-modal .group__additional-form {
    grid-template-columns: 1fr;
  }
  .side-modal.i-modal .long {
    grid-column-end: span 1;
  }
  .side-modal.i-modal .side-modal__grid {
    grid-template-columns: 1fr;
    row-gap: 24px;
  }
  .side-modal.i-modal .side-modal__title {
    font-size: 22px;
  }
  .side-modal.i-modal .group__title-subgroup {
    grid-column-start: 1;
    grid-column-end: 1;
  }
  .side-modal[data-side-modal-name=operation] .group.three-columns .group__form,
  .side-modal[data-side-modal-name=operation] .group.three-columns .group__additional-form,
  .side-modal[data-side-modal-name=observation] .group.three-columns .group__form,
  .side-modal[data-side-modal-name=observation] .group.three-columns .group__additional-form,
  .side-modal[data-side-modal-name=patient] .group.three-columns .group__form,
  .side-modal[data-side-modal-name=patient] .group.three-columns .group__additional-form {
    grid-template-columns: 1fr;
  }
  .side-modal[data-side-modal-name=operation] .group.three-columns .long,
  .side-modal[data-side-modal-name=observation] .group.three-columns .long,
  .side-modal[data-side-modal-name=patient] .group.three-columns .long {
    grid-column-end: span 1;
  }
  .side-modal[data-side-modal-name=operation] .file-loader,
  .side-modal[data-side-modal-name=observation] .file-loader,
  .side-modal[data-side-modal-name=patient] .file-loader {
    padding: 0 20px;
  }
}
@media (max-width: 575px) {
  .side-modal.i-modal .side-modal__header-main {
    flex-direction: column;
    align-items: start;
    row-gap: 10px;
  }
  .side-modal.i-modal .side-modal__bottom {
    flex-direction: column;
    row-gap: 10px;
    padding: 10px 40px;
  }
}
.statistics {
  background-color: #ffffff;
  padding: 4px 16px 4px 4px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 16px;
  border-radius: 16px;
}
.statistics__icon {
  width: 78px;
  height: 78px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f4f3ff;
  border-radius: 20px;
}
.statistics__icon svg {
  width: 38px;
  height: 38px;
  fill: none;
  stroke: #675dda;
}
.statistics__content {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  column-gap: 16px;
}
.statistics__title {
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
}
.statistics__subtitle, .statistics__total {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #625b71;
}
.statistics__total {
  align-self: start;
}

.success {
  width: 440px;
  padding: 70px 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 20px;
}
.success--email .success__subtitle {
  font-weight: 500;
}
.success img {
  width: 125px;
  height: 125px;
  border-radius: 50%;
  box-shadow: 0px 4px 20px 0px rgba(83, 240, 31, 0.25);
  margin-bottom: 33px;
}
.success__title {
  text-align: center;
  font-size: 24px;
  line-height: 32px;
  align-items: center;
  font-weight: 500;
  margin-bottom: 4px;
}
.success__subtitle {
  text-align: center;
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 20px;
  color: #49454f;
}

.tabs-wrapper {
  display: inline-flex;
  position: relative;
}

.tab {
  display: inline-block;
  padding: 12px;
  padding-top: 0;
  position: relative;
  cursor: pointer;
  transition: 0.25s linear;
  transition-property: color;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.15px;
}
@media (hover: hover) {
  .tab:hover {
    opacity: 0.85;
  }
}
.tab.is-active {
  color: #675dda;
}
.tab.is-active:before {
  background-color: #675dda;
  height: 4px;
}
.tab:before {
  transition: 0.25s linear;
  transition-property: background-color;
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  border-radius: 20px;
  background-color: #cac4d0;
}
.tab__name {
  margin-right: 4px;
}

.textarea {
  position: relative;
}
.textarea[data-connected] {
  display: none;
}
.textarea[data-connected].is-active {
  display: block;
}
.textarea.textarea--small textarea {
  height: 56px;
  padding: 15px 16px;
}
.textarea.textarea--small label {
  top: 50%;
}
.textarea__message {
  display: none;
  position: absolute;
  top: calc(100% + 4px);
  left: 16px;
  color: #49454f;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
}
.textarea__message.error-message {
  color: #b3261e;
}
.textarea textarea {
  display: block;
  width: 100%;
  height: 80px;
  border: 1px solid #49454f;
  border-radius: 4px;
  padding: 16px;
  resize: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  font-family: inherit;
}
.textarea textarea::-webkit-scrollbar {
  width: 12px;
  height: 12x;
  margin-right: 10px;
  padding-right: 10px;
}
.textarea textarea::-webkit-scrollbar-track {
  background-color: #ffffff;
  border-radius: 10px;
}
.textarea textarea::-webkit-scrollbar-thumb {
  border: 4px solid #ffffff;
  background-color: #79747e;
  border-radius: 10px;
}
.textarea label {
  position: absolute;
  top: 25px;
  left: 16px;
  display: flex;
  pointer-events: none;
  border: none;
  background-color: #ffffff;
  color: #49454f;
  padding: 0 4px;
  transform: translateY(-50%);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  transition: 0.2s linear;
  transition-property: top, font-size;
}
.textarea textarea:hover {
  border-color: #675dda;
}
.textarea textarea:focus {
  border-color: #675dda;
  box-shadow: 0px 0px 0px 1px #675dda;
}
.textarea textarea:focus ~ label {
  color: #675dda;
}
.textarea textarea:focus ~ label,
.textarea textarea:not(:placeholder-shown) ~ label {
  top: 0;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
}
.textarea textarea:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px #ffffff inset;
  -webkit-text-fill-color: #49454f;
}
.textarea textarea.is-invalid {
  border-color: #b3261e !important;
}
.textarea textarea.is-invalid ~ label {
  color: #b3261e;
}
.textarea textarea.is-invalid:focus {
  box-shadow: 0px 0px 0px 1px #b3261e;
}
.textarea:has(textarea.is-invalid) .textarea__message {
  display: block;
}
.textarea:has(textarea[data-required].hightlight) textarea {
  animation: shadowViolet 3s linear 0s infinite;
  border-color: #675dda;
}
.textarea:has(textarea.is-invalid[data-required].hightlight) textarea {
  animation: shadowRed 3s linear 0s infinite;
}

.user {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  column-gap: 8px;
}
.user.is-open .user__carret {
  transform: rotate(0deg);
}
.user__avatar-template {
  width: 40px;
  height: 40px;
  border: 1px solid #49454f;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.user__avatar-template svg {
  width: 24px;
  height: 24px;
  fill: none;
  stroke: #49454f;
}
.user__avatar {
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
}
.user__avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.user__username {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  color: #1c1b1f;
}
.user__carret {
  transition: 0.25s linear;
  transition-property: transform;
  width: 18px;
  height: 18px;
  stroke: #49454f;
  fill: none;
  transform: rotate(180deg);
}
.user .tooltip-menu {
  z-index: 100;
}

@media (max-width: 991px) {
  .user__username {
    font-size: 18px;
  }
}
@media (max-width: 575px) {
  .user__carret, .user__username {
    display: none;
  }
}
.viewing-modal {
  padding: 0;
  background: transparent;
}
.viewing-modal .i-modal__content {
  border-radius: 0;
  background: rgba(0, 0, 0, 0.6);
  max-width: none;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  padding-top: 96px;
}
.viewing-modal .i-modal__inner {
  overflow: hidden;
  height: 100%;
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.viewing-modal__header {
  position: absolute;
  top: 0;
  left: 0;
  padding: 24px 40px;
  width: 100%;
  height: 96px;
  background-color: #1c1b1f;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 32px;
}
.viewing-modal__file-name {
  flex: 1 1 0;
  color: #ffffff;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
}
.viewing-modal__buttons {
  flex: 1 1 0;
  display: flex;
  justify-content: flex-end;
  column-gap: 16px;
}
.viewing-modal__file {
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  width: 100%;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.viewing-modal__file img {
  width: auto;
  user-select: none;
  object-fit: cover;
}
.viewing-modal .btn {
  color: #ffffff;
}
.viewing-modal .btn svg {
  stroke: #ffffff;
}
.viewing-modal .btn-circle {
  border-color: #ffffff;
}
.viewing-modal .btn-circle svg {
  stroke: #ffffff;
}
.viewing-modal__close {
  margin-left: 32px;
}

.viewing-zoom {
  width: 128px;
  height: 40px;
  padding: 10px 16px;
  border: 1px solid #ffffff;
  border-radius: 30px;
  display: flex;
  justify-content: space-between;
}
.viewing-zoom__value {
  user-select: none;
}
.viewing-zoom__percents {
  width: 44px;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #ffffff;
}
.viewing-zoom__plus, .viewing-zoom__minus {
  width: 18px;
  height: 18px;
}
.viewing-zoom__plus svg, .viewing-zoom__minus svg {
  transition: 0.25s linear;
  transition-property: stroke;
  cursor: pointer;
  fill: none;
  stroke: #ffffff;
  width: 100%;
  height: 100%;
}
.viewing-zoom__plus:hover svg, .viewing-zoom__minus:hover svg {
  stroke: #675dda;
}

.anamnesis__wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
}
.anamnesis__item {
  background-color: #f4f3ff;
  border-radius: 10px;
  padding: 8px 16px;
}
.anamnesis__item.long {
  grid-column: 1/4;
}
.anamnesis__item-title {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #1c1b1f;
}
.anamnesis__item-text {
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #49454f;
}
.anamnesis__item-list {
  color: #49454f;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  padding-left: 30px;
}

@media (max-width: 767px) {
  .anamnesis__wrapper {
    grid-template-columns: 1fr 1fr;
  }
  .anamnesis__item.long {
    grid-column: 1/3;
  }
}
@media (max-width: 575px) {
  .anamnesis__wrapper {
    grid-template-columns: 1fr;
  }
  .anamnesis__item.long {
    grid-column: 1/2;
  }
}
.table-wrapper {
  overflow-x: auto;
  padding-bottom: 5px;
}

.concomitant-diseases__table {
  width: max-content;
}
.concomitant-diseases__title, .concomitant-diseases__name {
  padding: 10px 16px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #79747e;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.concomitant-diseases__title:first-child, .concomitant-diseases__name {
  text-align: end;
}
.concomitant-diseases__title {
  width: 150px;
  display: inline-block;
  flex-shrink: 0;
}
.concomitant-diseases__title br {
  display: none;
}
.concomitant-diseases__title:first-child {
  position: sticky;
  left: 0;
  z-index: 1;
  width: 138px;
  background: #ffffff;
}
.concomitant-diseases__row {
  white-space: nowrap;
  display: flex;
  width: 100%;
  align-items: center;
  gap: 12px;
}
.concomitant-diseases__row:not(:last-of-type) {
  margin-bottom: 12px;
}
.concomitant-diseases__diseases {
  column-gap: 12px;
}
.concomitant-diseases__name {
  width: 138px;
  display: inline-block;
  position: sticky;
  left: 0;
  z-index: 1;
  flex-shrink: 0;
  color: #1c1b1f;
  background: #ffffff;
}
.concomitant-diseases__changes {
  column-gap: 12px;
}

.concomitant-diseases__tab {
  width: 150px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  column-gap: 4px;
  padding: 8px 16px;
  border-radius: 10px;
  white-space: nowrap;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #1c1b1f;
}
.concomitant-diseases__tab svg {
  width: 16px;
  height: 16px;
  fill: none;
  stroke: #1c1b1f;
  opacity: 0.4;
}
.concomitant-diseases__tab.tab-exist {
  background-color: #fbddb9;
}
.concomitant-diseases__tab.tab-no-changes {
  background-color: #fbddb9;
}
.concomitant-diseases__tab.tab-decline {
  background-color: #fbb5b5;
}
.concomitant-diseases__tab.tab-improvement {
  background-color: #c0f5d8;
}
.concomitant-diseases__tab.tab-no {
  background-color: #f4eff4;
}

@media (max-width: 991px) {
  .concomitant-diseases__tab {
    padding: 4px;
  }
}
@media (max-width: 767px) {
  .concomitant-diseases__name, .concomitant-diseases__title {
    padding: 5px;
  }
  .concomitant-diseases__row:not(:last-of-type) {
    margin-bottom: 8px;
  }
}
@media (max-width: 575px) {
  .concomitant-diseases__title br {
    display: block;
  }
}
.control-panel__tools {
  display: flex;
  justify-content: space-between;
}
.control-panel__btns {
  display: flex;
  align-items: center;
  column-gap: 16px;
}
.control-panel__active-filters {
  transition: 0.25s linear;
  transition-property: margin;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.control-panel__active-filters:not(:empty) {
  margin-top: 24px;
}

@media (max-width: 767px) {
  .control-panel__tools {
    flex-direction: column;
    row-gap: 24px;
  }
}
@supports (-webkit-line-clamp: 6) {
  .your-class {
    display: -webkit-box;
    white-space: normal;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 6;
    overflow: hidden;
  }
}
.form-creating-observation {
  padding-bottom: 113px;
}

.form-creating-observation__patient-information .group__form {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: flex-start;
  gap: 44px 24px;
}

.form-creating-observation__gastric-bandage .group__form {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: flex-start;
  gap: 44px 24px;
}

.form-creating-observation__general-information .group__form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: flex-start;
  gap: 44px 24px;
}

.form-creating-observation__course-of-diseases .group__form,
.form-creating-observation__course-of-diseases .group__additional-form,
.form-creating-observation__women-health .group__form,
.form-creating-observation__women-health .group__additional-form {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: flex-start;
  gap: 44px 24px;
}

.form-creating-observation__additional-info .textarea {
  margin-bottom: 44px;
}

@media (max-width: 1400px) {
  .form-creating-observation__gastric-bandage .group__form {
    grid-template-columns: repeat(3, 1fr);
  }
  .form-creating-observation__gastric-bandage .long-input {
    grid-column-start: 1;
    grid-column-end: 4;
  }
}
@media (max-width: 1199px) {
  .form-creating-observation__patient-information .group__form,
  .form-creating-observation__patient-information .group__additional-form,
  .form-creating-observation__gastric-bandage .group__form,
  .form-creating-observation__gastric-bandage .group__additional-form,
  .form-creating-observation__general-information .group__form,
  .form-creating-observation__general-information .group__additional-form,
  .form-creating-observation__course-of-diseases .group__form,
  .form-creating-observation__course-of-diseases .group__additional-form,
  .form-creating-observation__women-health .group__form,
  .form-creating-observation__women-health .group__additional-form,
  .form-creating-observation__additional-info .group__form,
  .form-creating-observation__additional-info .group__additional-form {
    grid-template-columns: repeat(2, 1fr);
  }
  .form-creating-observation__patient-information .long-input,
  .form-creating-observation__gastric-bandage .long-input,
  .form-creating-observation__general-information .long-input,
  .form-creating-observation__course-of-diseases .long-input,
  .form-creating-observation__women-health .long-input,
  .form-creating-observation__additional-info .long-input {
    grid-column-start: 1;
    grid-column-end: 3;
  }
}
@media (max-width: 767px) {
  .form-creating-observation__patient-information .group__form,
  .form-creating-observation__patient-information .group__additional-form,
  .form-creating-observation__gastric-bandage .group__form,
  .form-creating-observation__gastric-bandage .group__additional-form,
  .form-creating-observation__general-information .group__form,
  .form-creating-observation__general-information .group__additional-form,
  .form-creating-observation__course-of-diseases .group__form,
  .form-creating-observation__course-of-diseases .group__additional-form,
  .form-creating-observation__women-health .group__form,
  .form-creating-observation__women-health .group__additional-form,
  .form-creating-observation__additional-info .group__form,
  .form-creating-observation__additional-info .group__additional-form {
    grid-template-columns: repeat(1, 1fr);
  }
  .form-creating-observation__patient-information .long-input,
  .form-creating-observation__gastric-bandage .long-input,
  .form-creating-observation__general-information .long-input,
  .form-creating-observation__course-of-diseases .long-input,
  .form-creating-observation__women-health .long-input,
  .form-creating-observation__additional-info .long-input {
    grid-column-start: 1;
    grid-column-end: 2;
  }
}
.form-creating-operation__general-information .group__form {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: flex-start;
  gap: 44px 24px;
}

.form-creating-operation__operation .long {
  grid-column-start: 1;
  grid-column-end: 3;
}
.form-creating-operation__operation .group__form,
.form-creating-operation__operation .group__additional-form {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: flex-start;
  gap: 44px 24px;
}
.form-creating-operation__operation .group__form[data-connected],
.form-creating-operation__operation .group__additional-form[data-connected] {
  display: none;
}
.form-creating-operation__operation .group__form[data-connected].is-active,
.form-creating-operation__operation .group__additional-form[data-connected].is-active {
  display: grid;
}
.form-creating-operation__operation .group__title-subgroup {
  grid-column-start: 1;
  grid-column-end: 3;
}
.form-creating-operation__operation .group--additional.border-none {
  border-top: none;
}

.form-creating-operation__complications .group__form,
.form-creating-operation__complications .group__additional-form,
.complication .group__form,
.complication .group__additional-form {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: flex-start;
  gap: 44px 24px;
}
.form-creating-operation__complications .group--additional:first-child,
.complication .group--additional:first-child {
  border-top: 0;
}

.form-creating-operation__complication .btn {
  justify-self: self-start;
  align-self: center;
}
.form-creating-operation__complication .textarea {
  grid-column-end: span 2;
}

.form-creating-operation__intraoperative .group__form,
.form-creating-operation__emergency-situations .group__form {
  grid-template-columns: repeat(3, 1fr);
}
.form-creating-operation__intraoperative .long-input,
.form-creating-operation__emergency-situations .long-input {
  grid-column-start: 2;
  grid-column-end: 4;
}

.form-creating-operation__hospital .group__form {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: flex-start;
  gap: 44px 24px;
}

.form-creating-operation__additional-info .textarea {
  margin-bottom: 44px;
}

@media (max-width: 1400px) {
  .form-creating-operation__general-information .group__form {
    grid-template-columns: repeat(3, 1fr);
  }
  .form-creating-operation__general-information .long-input {
    grid-column-start: 1;
    grid-column-end: 4;
  }
}
@media (max-width: 1199px) {
  .form-creating-operation__general-information .group__form,
  .form-creating-operation__general-information .group__additional-form,
  .form-creating-operation__operation .group__form,
  .form-creating-operation__operation .group__additional-form,
  .form-creating-operation__complications .group__form,
  .form-creating-operation__complications .group__additional-form,
  .form-creating-operation__complication .group__form,
  .form-creating-operation__complication .group__additional-form,
  .form-creating-operation__emergency-situations .group__form,
  .form-creating-operation__emergency-situations .group__additional-form,
  .form-creating-operation__additional-info .group__form,
  .form-creating-operation__additional-info .group__additional-form,
  .form-creating-operation__hospital .group__form,
  .form-creating-operation__hospital .group__additional-form {
    grid-template-columns: repeat(2, 1fr);
  }
  .form-creating-operation__general-information .long-input,
  .form-creating-operation__operation .long-input,
  .form-creating-operation__complications .long-input,
  .form-creating-operation__complication .long-input,
  .form-creating-operation__emergency-situations .long-input,
  .form-creating-operation__additional-info .long-input,
  .form-creating-operation__hospital .long-input {
    grid-column-start: 1;
    grid-column-end: 3;
  }
  .complication .group__form,
  .complication .group__additional-form {
    grid-template-columns: repeat(2, 1fr);
  }
  .complication .long-input {
    grid-column-start: 1;
    grid-column-end: 3;
  }
}
@media (max-width: 767px) {
  .form-creating-operation__general-information .group__form,
  .form-creating-operation__general-information .group__additional-form,
  .form-creating-operation__operation .group__form,
  .form-creating-operation__operation .group__additional-form,
  .form-creating-operation__complications .group__form,
  .form-creating-operation__complications .group__additional-form,
  .form-creating-operation__complication .group__form,
  .form-creating-operation__complication .group__additional-form,
  .form-creating-operation__emergency-situations .group__form,
  .form-creating-operation__emergency-situations .group__additional-form,
  .form-creating-operation__additional-info .group__form,
  .form-creating-operation__additional-info .group__additional-form,
  .form-creating-operation__hospital .group__form,
  .form-creating-operation__hospital .group__additional-form {
    grid-template-columns: repeat(1, 1fr);
  }
  .form-creating-operation__general-information .long-input,
  .form-creating-operation__operation .long-input,
  .form-creating-operation__complications .long-input,
  .form-creating-operation__complication .long-input,
  .form-creating-operation__emergency-situations .long-input,
  .form-creating-operation__additional-info .long-input,
  .form-creating-operation__hospital .long-input {
    grid-column-start: 1;
    grid-column-end: 2;
  }
  .complication .group__title {
    flex-direction: column;
  }
  .complication .group__add-info {
    align-self: start;
    margin: 0;
  }
  .complication .group__buttons {
    align-items: end;
    flex-direction: column-reverse;
  }
  .complication .group__form,
  .complication .group__additional-form {
    grid-template-columns: repeat(1, 1fr);
  }
  .complication .long-input {
    grid-column-start: 1;
    grid-column-end: 2;
  }
  .form-creating-operation__operation .group__title-subgroup {
    grid-column-start: 1;
    grid-column-end: auto;
  }
  .form-creating-operation__operation > .group__header .group__title {
    flex-wrap: wrap;
  }
  .form-creating-operation__operation > .group__header .group__add-info {
    margin: 0;
  }
  .form-creating-operation__complications > .group__header .group__subtitle {
    display: none;
  }
}
.form-new-patient__textarea {
  margin-bottom: 44px;
}
.form-new-patient__btn-wp {
  display: flex;
  column-gap: 20px;
}
.form-new-patient__btn {
  max-width: 325px;
  width: 100%;
}

.form-new-patient__pesonal-data .group__form {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: flex-start;
  gap: 44px 24px;
}
.form-new-patient__pesonal-data .long-input {
  grid-column-start: 1;
  grid-column-end: 5;
}

.form-new-patient__main-data .group__form {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: flex-start;
  gap: 44px 24px;
}
.form-new-patient__main-data .long-input {
  display: flex;
  grid-column-start: 1;
  grid-column-end: 4;
}

.form-new-patient__concomitant-diseases .group__form,
.form-new-patient__concomitant-diseases .group__additional-form {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: flex-start;
  gap: 44px 24px;
}
.form-new-patient__concomitant-diseases .long-input {
  display: flex;
  grid-column-start: 2;
  grid-column-end: 4;
}

.form-new-patient__anamnesis .group__form {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  gap: 44px 24px;
}

@media (max-width: 1400px) {
  .form-new-patient__pesonal-data .group__form {
    grid-template-columns: repeat(3, 1fr);
  }
  .form-new-patient__pesonal-data .long-input {
    grid-column-start: 1;
    grid-column-end: 4;
  }
}
@media (max-width: 1199px) {
  .form-new-patient__pesonal-data .group__form,
  .form-new-patient__pesonal-data .group__additional-form,
  .form-new-patient__main-data .group__form,
  .form-new-patient__main-data .group__additional-form,
  .form-new-patient__concomitant-diseases .group__form,
  .form-new-patient__concomitant-diseases .group__additional-form,
  .form-new-patient__anamnesis .group__form,
  .form-new-patient__anamnesis .group__additional-form {
    grid-template-columns: repeat(2, 1fr);
  }
  .form-new-patient__pesonal-data .long-input,
  .form-new-patient__main-data .long-input,
  .form-new-patient__concomitant-diseases .long-input,
  .form-new-patient__anamnesis .long-input {
    grid-column-start: 1;
    grid-column-end: 3;
  }
}
@media (max-width: 767px) {
  .form-new-patient__pesonal-data .group__form,
  .form-new-patient__pesonal-data .group__additional-form,
  .form-new-patient__main-data .group__form,
  .form-new-patient__main-data .group__additional-form,
  .form-new-patient__concomitant-diseases .group__form,
  .form-new-patient__concomitant-diseases .group__additional-form,
  .form-new-patient__anamnesis .group__form,
  .form-new-patient__anamnesis .group__additional-form {
    grid-template-columns: repeat(1, 1fr);
  }
  .form-new-patient__pesonal-data .long-input,
  .form-new-patient__main-data .long-input,
  .form-new-patient__concomitant-diseases .long-input,
  .form-new-patient__anamnesis .long-input {
    grid-column-start: 1;
    grid-column-end: 2;
  }
}
.header {
  transition: 0.25s linear;
  transition-property: padding;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  box-shadow: 0px 8px 44px 0px rgba(0, 0, 0, 0.04);
  padding-top: 24px;
  padding-bottom: 24px;
  background-color: #ffffff;
}
.header__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 40px;
}
.header__logo {
  width: 48px;
  height: 48px;
}
.header__logo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.header__nav {
  display: flex;
  flex-grow: 1;
  column-gap: 24px;
}
.header__nav-el {
  color: #6750a4;
  font-size: 20px;
  cursor: pointer;
  font-weight: 500;
  line-height: 26px;
  transition: 0.25s linear;
  transition-property: color;
}
@media (hover: hover) {
  .header__nav-el:hover {
    color: #675dda;
  }
}
.header__buttons {
  display: flex;
  align-items: center;
  column-gap: 20px;
}
.header__mobile-menu {
  display: none;
}

.burger {
  cursor: pointer;
}
.burger .burger-line {
  transition: 0.25s linear;
  transition-property: transform;
  width: 25px;
  display: block;
  height: 2px;
  background-color: #675dda;
  margin-bottom: 5px;
}
.burger .burger-line:last-child {
  margin-bottom: 0;
}
.burger.is-active .burger-line {
  background-color: #79747e;
}
.burger.is-active .burger-line:nth-child(1) {
  transform: rotate(-45deg) translateY(5px) translateX(0);
}
.burger.is-active .burger-line:nth-child(2) {
  transform: rotate(45deg) translateY(-5px) translateX(0);
}
.burger.is-active .burger-line:nth-child(3) {
  display: none;
}

.home .header__logo {
  display: none;
}
.home .header__sign-in-btn {
  width: 325px;
}
.home .header__registration-btn {
  width: 220px;
}

@media (max-width: 991px) {
  .home main {
    padding-top: 60px;
  }
  .home .logo-full__logo {
    display: none;
  }
  .home .header {
    padding: 12px 0;
  }
  .home .header .header__logo {
    display: block;
  }
  .home .header__sign-in-btn {
    width: 180px;
  }
  .home .header__registration-btn {
    display: none;
  }
  .home .header__inner {
    grid-template-columns: 48px auto;
  }
  .home .header__buttons {
    justify-content: flex-end;
  }
  .header {
    padding: 10px 0;
  }
  .header.menu-is-open .header__nav {
    transform: translateX(0);
  }
  .header__inner {
    width: 100%;
    display: grid;
    align-items: center;
    justify-content: initial;
    column-gap: 15px;
    grid-template-columns: 48px auto 25px;
  }
  .header__logo {
    flex-grow: 0;
    width: 40px;
    height: 40px;
  }
  .header__nav {
    transition: 0.25s linear;
    transition-property: transform;
    transform: translateX(100%);
    position: absolute;
    background-color: #f4f3ff;
    right: 0;
    z-index: 70;
    top: 60px;
    height: calc(100vh - 60px);
    box-shadow: 8px 10px 20px 0px rgba(0, 0, 0, 0.1);
    flex-direction: column;
    row-gap: 10px;
    width: 250px;
    padding: 20px;
  }
  .header__nav-el {
    font-size: 18px;
  }
  .header__mobile-menu {
    display: block;
    justify-self: end;
  }
  .header .user {
    flex-grow: 0;
    justify-self: end;
  }
}
@media (max-width: 767px) {
  .header__nav {
    flex-grow: 0;
    display: inline-flex;
  }
  .header .header__buttons {
    justify-content: flex-end;
    column-gap: 15px;
  }
  .header.is-blocked {
    pointer-events: none;
  }
}
@media (max-width: 575px) {
  .header__nav-el {
    font-size: 16px;
  }
}
.hero {
  overflow: hidden;
  padding-top: 52px;
  padding-bottom: 40px;
}
.hero.fix-height {
  height: 338px;
}
.hero__inner {
  position: relative;
}
.hero__title {
  color: #675dda;
}
.hero__title br.adaptive {
  display: none;
}
.hero__back {
  margin-bottom: 8px;
}
.hero__add-patient {
  width: 210px;
  position: absolute;
  right: 0;
  bottom: 0;
}
.hero__image {
  position: absolute;
  z-index: -1;
  top: -53px;
  right: 115px;
}
.hero__image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.hero__passed-time {
  margin-top: 24px;
  display: inline-block;
  padding: 8px 20px;
  border-radius: 10px;
  background-color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #49454f;
}

@media (max-width: 1199px) {
  .hero__title {
    font-size: 60px;
  }
  .hero__image {
    top: 0;
    right: 50px;
    width: 540px;
  }
}
@media (max-width: 991px) {
  .hero:has(.hero__image) {
    padding-bottom: 194px;
  }
  .hero__title {
    font-size: 44px;
  }
  .hero__title br {
    display: none;
  }
  .hero__image {
    top: 75px;
    right: 55px;
    position: absolute;
  }
}
@media (max-width: 767px) {
  .hero {
    padding-top: 32px;
    padding-bottom: 32px !important;
  }
  .hero.fix-height {
    height: auto;
  }
  .hero__title {
    font-weight: 500;
    line-height: 1.2;
  }
  .hero__image {
    display: none;
  }
  .hero__add-patient {
    position: relative;
  }
  .patient-list .hero__inner {
    display: flex;
    align-items: center;
    column-gap: 20px;
  }
  .patient-list .hero__add-patient {
    flex-shrink: 0;
    width: 65px;
    height: 65px;
    border-radius: 50%;
  }
  .patient-list .hero__add-patient span {
    display: none;
  }
  .patient-list .hero__add-patient svg {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
  }
}
.home-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.home-content__frame {
  height: 560px;
  border-radius: 20px;
  background-color: #ffffff;
}

.login-form {
  width: 440px;
  margin: 0 auto;
}
.login-form form .input-custom:first-of-type {
  margin-bottom: 30px;
}
.login-form__forgot {
  display: inline-block;
  font-weight: 500;
  margin-top: 24px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #675dda;
}
.login-form__bottom {
  margin-top: 44px;
}
.login-form__bottom .submit-button {
  width: 100%;
  margin-bottom: 16px;
}
.login-form__bottom p {
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #625b71;
}
.login-form__bottom a {
  color: #675dda;
}

.patient-card__wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  column-gap: 20px;
}
.patient-card__main {
  flex-grow: 1;
  width: calc(100% - 325px);
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.patient-card__decor-line {
  width: 100%;
  height: 2px;
  background-color: #e8eaee;
  margin: 24px 0;
}
.patient-card__bottom {
  display: flex;
  justify-content: flex-end;
}
.patient-card__bottom .btn {
  max-width: 325px;
  width: 100%;
}
.patient-card__loader .file-loader__add-file {
  display: none;
}
.patient-card__files {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}
.patient-card__files:empty {
  display: none;
}

.patient-card__complications,
.patient-card__operations {
  display: flex;
  flex-wrap: wrap;
  column-gap: 16px;
  row-gap: 16px;
}

.patient-card__mgb {
  width: 237px;
}

.patient-card__files-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.chart-wrapper {
  position: relative;
  height: 286px;
  width: 100%;
}

.losing-weight-section .page-section__content {
  overflow-x: auto;
}

@media (max-width: 1199px) {
  .patient-card__wrapper {
    flex-direction: column;
    row-gap: 20px;
  }
  .patient-card__main {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .patient-card .chart-wrapper {
    width: 700px;
    height: 240px;
  }
}
.patient-table {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
.patient-table.is-empty .patient-table__body {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 84px 0;
}
.patient-table.is-empty .patient-table__header {
  top: 0;
  position: relative;
}
.patient-table.is-empty .patient-table__empty {
  display: inline-flex;
}
.patient-table__header {
  border-radius: 0 0 10px 10px;
  position: sticky;
  padding-top: 24px;
  z-index: 5;
  top: 95px;
  background-color: #f4f3ff;
}
.patient-table__header.shadow .patient-table__header-inner {
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
}
.patient-table__header-inner {
  background-color: #ffffff;
  padding: 16px 32px;
  border-radius: 20px 20px 10px 10px;
  display: grid;
  grid-template-columns: minmax(auto, 231px) 72px 45px 83px minmax(auto, 231px) 92px 101px minmax(auto, 129px) 93px 40px;
  column-gap: 20px;
}
.patient-table__body {
  padding: 16px 32px;
  border-radius: 10px 10px 20px 20px;
  background-color: #ffffff;
}
.patient-table__empty {
  display: none;
  align-items: center;
  flex-direction: column;
}
.patient-table__empty > span {
  display: block;
  margin-bottom: 8px;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
}
.patient-table__empty .btn {
  margin-bottom: 12px;
}
.patient-table__empty img {
  width: 170px;
  height: 122px;
  object-fit: cover;
}

.parametr {
  display: inline-flex;
  align-items: center;
  column-gap: 4px;
}
.parametr.is-up path.up {
  stroke: #675dda;
}
.parametr.is-down path.down {
  stroke: #675dda;
}
.parametr__name {
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  white-space: nowrap;
  letter-spacing: 0.1px;
  color: #79747e;
}
.parametr svg {
  fill: none;
  stroke: #79747e;
}

.patient {
  position: relative;
  cursor: pointer;
  display: grid;
  grid-template-columns: minmax(auto, 231px) 72px 45px 83px minmax(auto, 231px) 92px 101px minmax(auto, 129px) 93px 40px;
  align-items: center;
  column-gap: 20px;
  padding-bottom: 16px;
}
.patient:not(:last-child) {
  margin-bottom: 16px;
}
.patient::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #e8eaee;
  height: 1px;
  border-radius: 20px;
}
.patient__param {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.1px;
}
.patient .event {
  display: block;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: #79747e;
}
.patient__age, .patient__gender, .patient__IMT {
  text-align: center;
}
.patient__operations {
  display: flex;
  flex-wrap: wrap;
  gap: 8px 4px;
}
.patient__actions {
  position: relative;
  cursor: pointer;
  width: 40px;
  height: 40px;
  padding: 8px;
  background-color: #f4f3ff;
  border-radius: 50%;
}
.patient__actions .dots-icon {
  width: 100%;
  height: 100%;
  fill: #49454f;
  stroke: none;
}

@media (max-width: 767px) {
  .patient-table__header {
    top: 67px;
  }
}
.profile {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
.profile.is-editable {
  padding-bottom: 90px;
}
.profile.is-editable .profile__footer {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
.profile.is-editable .profile__edit-btn {
  display: none;
}
.profile.is-editable .profile__personal-data {
  pointer-events: initial;
}
.profile__personal-data {
  display: flex;
  column-gap: 24px;
}
.profile__personal-grid,
.profile .change-password {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: flex-start;
  gap: 44px 24px;
}
.profile .change-password {
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
}
.profile__places-of-work {
  display: grid;
  grid-template-columns: repeat(2, minmax(auto, 642px));
  gap: 12px;
  margin-bottom: 24px;
}
.profile__hint {
  margin-top: 24px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #79747e;
}
.profile__personal-data {
  pointer-events: none;
}
.profile__assistants {
  display: grid;
  grid-template-columns: repeat(2, minmax(auto, 642px));
  gap: 12px;
}
.profile__footer {
  transition: 0.25s linear;
  transition-property: visibility, transform, opacity;
  width: 100%;
  position: fixed;
  z-index: 100;
  bottom: 0;
  left: 0;
  padding: 24px 40px;
  background-color: #ffffff;
  box-shadow: 0px -8px 44px 0px rgba(0, 0, 0, 0.04);
  border-top: 2px solid #e8eaee;
  transform: translateY(100%);
  opacity: 0;
  visibility: hidden;
}
.profile__footer-inner {
  display: flex;
  column-gap: 20px;
  justify-content: flex-end;
  align-items: center;
}
.profile__footer .btn {
  max-width: 240px;
  width: 100%;
}

.password-message {
  margin-top: 20px;
}

.avatar-loader {
  position: relative;
  flex-shrink: 0;
  width: 156px;
  height: 156px;
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid #cac4d0;
}
.avatar-loader.has-avatar label {
  opacity: 0;
}
.avatar-loader input[type=file] {
  display: none;
}
.avatar-loader label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.avatar-loader__title {
  text-align: center;
  align-items: center;
}
.avatar-loader__title svg {
  margin: 0 auto;
  margin-bottom: 8px;
  display: block;
  width: 24px;
  height: 24px;
  fill: none;
  stroke: #49454f;
}
.avatar-loader__avatar {
  border-radius: 10px;
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.place-of-work,
.assistant {
  width: 100%;
  background-color: #f4f3ff;
  padding: 16px;
  border-radius: 10px;
  display: flex;
  column-gap: 16px;
  align-items: flex-start;
  justify-content: space-between;
}
.place-of-work__content,
.assistant__content {
  display: flex;
  column-gap: 16px;
}
.place-of-work__number,
.assistant__number {
  white-space: nowrap;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #79747e;
}
.place-of-work__name,
.assistant__name {
  display: block;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #1c1b1f;
}
.place-of-work__place,
.assistant__place {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
}
.place-of-work__btns,
.assistant__btns {
  display: flex;
  column-gap: 16px;
}
.place-of-work__remove, .place-of-work__edit,
.assistant__remove,
.assistant__edit {
  flex-shrink: 0;
  width: 18px;
  height: 18px;
}

.assistant__person {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #1c1b1f;
}
.assistant__name {
  display: inline;
}

@media (max-width: 1199px) {
  .profile__personal-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 991px) {
  .profile__personal-data {
    flex-direction: column;
    align-items: center;
    row-gap: 24px;
  }
  .profile__personal-grid,
  .profile .change-password {
    grid-template-columns: repeat(1, 1fr);
    gap: 24px;
  }
  .profile__places-of-work, .profile__assistants {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 767px) {
  .profile__hint {
    font-weight: 400;
  }
}
.recovery-password-form {
  width: 440px;
  margin: 0 auto;
}
.recovery-password-form__subtitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #49454f;
  margin-bottom: 24px;
}
.recovery-password-form__bottom {
  margin-top: 44px;
}
.recovery-password-form__bottom .submit-button {
  width: 100%;
  margin-bottom: 16px;
}
.recovery-password-form__links {
  display: flex;
  justify-content: space-between;
}
.recovery-password-form__links a {
  color: #675dda;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  flex-grow: 1;
}
.recovery-password-form .page-section__header {
  margin-bottom: 4px;
}

.register-form {
  width: 440px;
  margin: 0 auto;
}
.register-form__fields {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}
.register-form__bottom {
  margin-top: 44px;
}
.register-form__bottom .submit-button {
  width: 100%;
  margin-bottom: 16px;
}
.register-form__bottom p {
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #625b71;
}
.register-form__bottom a {
  color: #675dda;
}

.observations {
  padding: 0 40px;
  margin-bottom: 28px;
}
.observations__wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.observations__title {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  margin-bottom: 16px;
}
.observations__add, .observations__observation {
  flex-shrink: 0;
  max-width: 100px;
  width: 100%;
  background-color: transparent;
  border: 1px solid #675dda;
  border-radius: 8px;
  outline: none;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
}
.observations__add svg, .observations__observation svg {
  width: 18px;
  height: 18px;
  fill: none;
  stroke: #675dda;
}
.observations__observation {
  border-color: #79747e;
}

.side-modal__add-complication {
  display: none;
}
.side-modal__add-complication.is-active {
  display: block;
}

@media (max-width: 767px) {
  .observations {
    padding: 0 20px;
  }
}
.statistics-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}
.statistics-wrapper .statistics {
  flex-grow: 1;
}

@media (max-width: 1199px) {
  .statistics-wrapper {
    justify-content: center;
  }
}
.support-modal.i-modal {
  overflow: hidden;
  z-index: 50;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  padding: 0;
  opacity: 1;
  transition-property: transform;
  transform: translateX(100%);
  background-color: transparent;
}
.support-modal.i-modal.is-active {
  opacity: 1;
  visibility: visible;
  z-index: 50;
  transform: translateX(0);
}
.support-modal.i-modal.is-active .i-modal__content {
  opacity: 1;
}
.support-modal.i-modal .i-modal__content {
  max-width: 940px;
  opacity: 1;
  margin: 0;
  margin-left: auto;
  padding: 0;
  padding-top: 96px;
  width: 100%;
  height: 100%;
  border-radius: 0;
  transform: none;
}
.support-modal.i-modal .i-modal__inner {
  position: relative;
  height: 100%;
}
.support-modal.i-modal form {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.support-modal.i-modal .support-modal__header {
  position: sticky;
  z-index: 4;
  top: 0;
  background-color: #ffffff;
  left: 0;
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 24px;
  padding-bottom: 24px;
}
.support-modal.i-modal .support-modal__header.is-scrolled {
  margin-bottom: 0;
}
.support-modal.i-modal .support-modal__header.is-scrolled::before {
  display: none;
}
.support-modal.i-modal .support-modal__add-txt {
  display: none;
  align-items: center;
  column-gap: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.1px;
  margin-bottom: 8px;
  color: #49454f;
  cursor: pointer;
}
.support-modal.i-modal .support-modal__add-txt svg {
  width: 18px;
  height: 18px;
  stroke: #49454f;
  fill: none;
  transform: rotate(90deg);
}
.support-modal.i-modal .support-modal__header-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.support-modal.i-modal .support-modal__header-buttons {
  display: flex;
  align-items: center;
  column-gap: 16px;
}
.support-modal.i-modal .support-modal__header-buttons .btn {
  display: none;
}
.support-modal.i-modal .btn.support-modal__close {
  display: inline-flex;
}
.support-modal.i-modal .support-modal__remove svg {
  stroke: #dc362e;
}
.support-modal.i-modal .support-modal__title {
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  color: #000000;
}
.support-modal.i-modal .support-modal__main {
  position: relative;
  padding: 0;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  overflow: auto;
  height: calc(100% - 174px);
}
.support-modal.i-modal .support-modal__bottom {
  position: absolute;
  left: 0;
  z-index: 2;
  bottom: 0;
  padding: 24px 40px;
  background-color: #ffffff;
  border-top: 2px solid #e8eaee;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 20px;
}
.support-modal.i-modal .support-modal__bottom .btn {
  max-width: 240px;
  width: 100%;
}

.support-modal .hidden {
  display: none;
}
.support-modal__success {
  position: absolute;
  inset: 0;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
  z-index: 3;
}
.support-modal__success-wrap {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.support-modal__success h2 {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
}
.support-modal__success span {
  padding-top: 4px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.support-modal__success-btn {
  padding-top: 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #675dda;
  cursor: pointer;
}

@media (max-width: 767px) {
  .support-modal.i-modal .i-modal__content {
    padding-top: 60px;
  }
  .support-modal.i-modal .support-modal__header {
    padding: 20px;
  }
  .support-modal.i-modal .support-modal__main {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
  }
  .support-modal.i-modal .group__form,
  .support-modal.i-modal .group__additional-form {
    grid-template-columns: 1fr;
  }
  .support-modal.i-modal .long {
    grid-column-end: span 1;
  }
  .support-modal.i-modal .support-modal__grid {
    grid-template-columns: 1fr;
    row-gap: 24px;
  }
  .support-modal.i-modal .support-modal__title {
    font-size: 22px;
  }
  .support-modal.i-modal .group__title-subgroup {
    grid-column-start: 1;
    grid-column-end: 1;
  }
  .support-modal.i-modal .support-modal-content__info {
    padding: 30px 25px;
  }
  .support-modal.i-modal .support-modal-content__inpt-wrap {
    padding: 30px 25px;
  }
}
@media (max-width: 575px) {
  .support-modal.i-modal .support-modal__bottom {
    flex-direction: column;
    row-gap: 10px;
    padding: 10px 40px;
  }
}
.support-modal-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.support-modal-content__info {
  background-color: rgb(244, 243, 255);
  padding: 32px 40px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.support-modal-content__info-item {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.support-modal-content__info-icon {
  min-width: 40px;
  min-height: 40px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: #675dda;
  background-color: rgb(255, 255, 255);
}
.support-modal-content__inpt-wrap {
  display: flex;
  flex-direction: column;
  padding: 44px 40px 24px;
  gap: 44px;
  flex: 1;
}
.support-modal-content__textarea-wrap {
  height: 100%;
}
.support-modal-content__textarea-wrap .textarea {
  height: 100%;
}
.support-modal-content__textarea-wrap .textarea textarea {
  min-height: 130px;
  height: 100%;
}
.support-modal-content__textarea-wrap span {
  padding: 4px 16px 0;
  color: #49454f;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}
.support-modal-content__title {
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  padding-bottom: 8px;
}

/*!*
 * ВНИМАНИЕ! Этот файл генерируется автоматически.
 * Любые изменения этого файла будут потеряны при следующей компиляции.
 * Любое изменение проекта без возможности компиляции ДОЛЬШЕ И ДОРОЖЕ в 2-5 раз.
 */