.file {
  position: relative;
  background-color: $violet;
  padding: 8px 16px;
  // padding-right: 28px;
  border-radius: 10px;
  display: flex;
  column-gap: 8px;
  align-items: center;
  justify-content: space-between;

  &.uploaded {
    .loader {
      display: none;
    }

    .file__uploaded {
      display: flex;
    }
  }

  &.attached {
    max-width: 252px;
    width: 100%;

    .file__delete {
      display: flex;
    }

    .loader {
      display: none;
    }
  }

  &--advanced {
    height: 52px;
    width: 280px;
    background-color: $white;
    border: 1px solid $grey;
    padding: 8px 16px 8px 8px;

    &.is-active {
      .file__menu {
        opacity: 1;
        visibility: visible;
      }

      .file__carret {
        .file__carret-arrow {
          transform: rotate(0);
        }
      }
    }

    .file__name-wrapper {
      max-width: calc(100% - 26px);
    }

    .file__icon {
      width: 36px;
      height: 36px;
      svg {
        stroke: $grey-light;
      }
    }

    .file__name {
      color: $grey-dark;
    }

    .file__type {
      color: $grey;
    }

    .file__carret {
      display: block;
    }

    .file__image {
      width: 36px;
      height: 36px;
      border-radius: 6px;
      overflow: hidden;
    }

    .loader {
      display: none;
    }
  }

  &__name-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    column-gap: 8px;
  }

  &__info {
    max-width: 75%;
    flex-grow: 1;
  }

  &__name {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    white-space: nowrap;
    color: $white;
  }

  &__type {
    max-width: 172px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: $violet-light;
  }

  &__icon {
    flex-shrink: 0;
    width: 44px;
    height: 44px;

    svg {
      width: 100%;
      height: 100%;
      fill: none;
      stroke: $white;
    }
  }

  &__delete {
    position: absolute;
    cursor: pointer;
    display: none;
    align-items: center;
    justify-content: center;
    top: 8px;
    right: 8px;
    width: 12px;
    height: 12px;

    svg {
      pointer-events: none;
      fill: none;
      stroke: $white;
      width: 100%;
      height: 100%;
    }
  }

  &__carret {
    flex-shrink: 0;
    cursor: pointer;
    position: relative;
    display: none;
    width: 18px;
    height: 18px;

    svg {
      width: 100%;
      height: 100%;
      fill: none;
      stroke: $grey-light;
    }

    .file__carret-arrow {
      @include transition('transform');
      transform: rotate(180deg);
    }
  }

  &__uploaded {
    display: none;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    background-color: $white;
    border-radius: 50%;

    svg {
      fill: none;
      width: 65%;
      height: 65%;
      stroke: $green;
    }
  }

  &__img {
    flex-shrink: 0;
    width: 36px;
    height: 36px;
    border: 1px solid $white;
    border-radius: 6px;
    object-fit: cover;
  }

  &__menu {
    @include transition('opacity, visibility');
    position: absolute;
    opacity: 0;
    visibility: hidden;
    top: 100%;
    left: 0;
    z-index: 5;
    // width: 144px;
    height: auto;
    padding: 8px 0;
    list-style: none;
    background: linear-gradient(0deg, rgba(103, 80, 164, 0.08), rgba(103, 80, 164, 0.08)), #fffbfe;
    border-radius: 4px;
    box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.3);
  }

  &__menu-item {
    cursor: pointer;
    min-width: 144px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 16px;
    padding: 12px 16px;

    &:has(a) {
      display: block;
      padding: 0;
      a {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        column-gap: 16px;
        padding: 12px 16px;
      }
    }

    @media (hover: hover) {
      &:hover {
        svg {
          stroke: $violet;
        }
        // color: $violet-light;
      }
    }

    // txt
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: $grey-dark;
    white-space: nowrap;

    svg {
      flex-shrink: 0;
      width: 24px;
      height: 24px;
      color: $grey;
    }
  }
}

.loader {
  width: 24px;
  height: 24px;
  border: 2px solid $white;
  border-bottom-color: $green;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: $mobile-big) {
  .file {
    &__menu {
      left: auto;
      right: 0;
    }
  }
}
