.form {
  &__wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }

  &__wrapper-btns {
    display: flex;
    column-gap: 20px;
    button {
      max-width: 325px;
      width: 100%;
    }
  }
}

@media (max-width: $mobile) {
  .form {
    &__wrapper-btns {
      flex-direction: column;
      row-gap: 20px;
    }
  }
}
