.profile {
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  &.is-editable {
    padding-bottom: 90px;
    .profile__footer {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }

    .profile__edit-btn {
      display: none;
    }

    .profile__personal-data {
      pointer-events: initial;
    }
  }

  &__personal-data {
    display: flex;
    column-gap: 24px;
  }

  &__personal-grid,
  .change-password {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: flex-start;
    gap: 44px 24px;
  }

  .change-password {
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
  }

  &__places-of-work {
    display: grid;
    grid-template-columns: repeat(2, minmax(auto, 642px));
    gap: 12px;
    margin-bottom: 24px;
  }

  &__hint {
    margin-top: 24px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: $grey-light;
  }

  &__personal-data {
    pointer-events: none;
  }

  &__assistants {
    display: grid;
    grid-template-columns: repeat(2, minmax(auto, 642px));
    gap: 12px;
  }

  &__footer {
    @include transition('visibility, transform, opacity');
    width: 100%;
    position: fixed;
    z-index: 100;
    bottom: 0;
    left: 0;
    padding: 24px 40px;
    background-color: $white;
    box-shadow: 0px -8px 44px 0px rgba(0, 0, 0, 0.04);
    border-top: 2px solid $grey-super-light;
    transform: translateY(100%);
    opacity: 0;
    visibility: hidden;

    &-inner {
      display: flex;
      column-gap: 20px;
      justify-content: flex-end;
      align-items: center;
    }

    .btn {
      max-width: 240px;
      width: 100%;
    }
  }
}

.password-message {
  margin-top: 20px;
}

.avatar-loader {
  position: relative;
  flex-shrink: 0;
  width: 156px;
  height: 156px;
  overflow: hidden;
  border-radius: 10px;
  border: 1px solid #cac4d0;

  &.has-avatar {
    label {
      opacity: 0;
    }
  }

  input[type='file'] {
    display: none;
  }

  label {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  &__title {
    text-align: center;
    align-items: center;
    svg {
      margin: 0 auto;
      margin-bottom: 8px;
      display: block;
      width: 24px;
      height: 24px;
      fill: none;
      stroke: $grey;
    }
  }

  &__avatar {
    border-radius: 10px;
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.place-of-work,
.assistant {
  // max-width: 642px;
  width: 100%;
  background-color: $background;
  padding: 16px;
  border-radius: 10px;
  display: flex;
  column-gap: 16px;
  align-items: flex-start;
  justify-content: space-between;

  &__content {
    display: flex;
    column-gap: 16px;
  }

  &__number {
    white-space: nowrap;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: $grey-light;
  }

  &__name {
    display: block;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: $grey-dark;
  }

  &__place {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
  }

  &__btns {
    display: flex;
    column-gap: 16px;
  }

  &__remove,
  &__edit {
    flex-shrink: 0;
    width: 18px;
    height: 18px;
  }
}

.assistant {
  &__person {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: $grey-dark;
  }

  &__name {
    display: inline;
  }
}

@media (max-width: $desktop) {
  .profile {
    &__personal-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media (max-width: $tablet) {
  .profile {
    &__personal-data {
      flex-direction: column;
      align-items: center;
      row-gap: 24px;
    }

    &__personal-grid,
    .change-password {
      grid-template-columns: repeat(1, 1fr);
      gap: 24px;
    }

    &__places-of-work,
    &__assistants {
      grid-template-columns: 1fr;
    }
  }
}

@media (max-width: $mobile-big) {
  .profile {
    &__hint {
      font-weight: 400;
    }
  }
}
