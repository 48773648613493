.statistics-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;

  .statistics {
    flex-grow: 1;
  }
}

@media (max-width: $desktop) {
  .statistics-wrapper {
    justify-content: center;
  }
}
