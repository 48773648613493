.general-info {
  position: sticky;
  top: 116px;
  flex-shrink: 0;
  width: 325px;
  padding: 32px;
  background-color: $white;
  border-radius: 20px;
  &__name {
    display: block;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
  }

  &__tab {
    margin: 12px 0;
    background-color: $violet-deep;
    border-radius: 10px;
    padding: 8px 16px;
    display: flex;
    align-items: start;
    justify-content: space-around;
    column-gap: 16px;
  }

  &__tab-title,
  &__weight-title {
    display: block;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    padding-bottom: 2px;
    letter-spacing: 0.4px;
    color: $violet-light;
  }

  &__weight-title {
    color: #49454f;
  }

  &__tab-value,
  &__weight-value {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: $white;
  }

  &__weight-value {
    color: $grey-dark;
  }

  &__tab-decor {
    display: block;
    height: 30px;
    width: 1px;
    background-color: $violet-2;
  }

  &__weight {
    border: 1px solid $grey-super-light;
    border-radius: 10px;
    position: relative;
    padding: 8px 16px;

    &-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 16px;
    }

    &-el {
      line-height: 24px;
    }

    &-decor {
      width: 100%;
      margin: 8px 0;
      height: 1px;
      background-color: $grey-super-light;
    }
  }

  &__decor {
    display: block;
    width: 100%;
    background-color: $grey-super-light;
    height: 2px;
    margin: 24px 0;
  }

  &__contacts {
    margin: 24px 0;
  }

  &__contacts-el {
    display: flex;
    align-items: start;
    column-gap: 8px;

    svg {
      flex-shrink: 0;
      width: 24px;
      height: 24px;
      fill: none;
      stroke: $grey-light-100;
    }

    // txt
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: $grey-dark;
  }

  &__contacts-title {
    display: block;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    margin-bottom: 12px;
  }

  &__contacts-content {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }

  &__note {
    padding: 8px 16px;
    background-color: $violet-deep;
    border-radius: 10px;
  }

  &__note-title {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: $violet-light;
  }

  &__note-content {
    color: $white;
    letter-spacing: 0.5px;
  }

  &__actions {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }
}

.remove-card {
  color: $red-btn;
  svg {
    stroke: $red-btn;
  }
}

@media (max-width: $desktop) {
  .general-info {
    position: initial;
    width: 100%;
    display: grid;
    // align-items: center;
    gap: 12px;
    grid-template-columns: repeat(3, 1fr);
    // flex-wrap: wrap;

    &__name {
      align-self: center;
    }

    &__decor {
      grid-column: 1 / 4;
    }

    &__tab {
      margin: 0;
    }

    &__contacts {
      grid-column: 1 / 3;
      background-color: $background;
      padding: 8px 16px;
      border-radius: 10px;
    }

    &__note {
      grid-row: 4;
      grid-column: 1 / 4;
    }

    &__actions {
      margin-top: 0;
    }
  }
}

@media (max-width: $tablet) {
  .general-info {
    display: block;

    &__tab {
      margin: 12px 0;
      justify-content: space-around;
    }

    &__actions {
      margin-top: 24px;
    }
  }
}
